import { GenericAction } from '../../../../common/state/models/generic-action.model';
import { RoleActionTypes } from '../actions/role-action-types';
import { IRoleState, RoleState, IPermissionResponse} from '../models/role.model';
import { errorItemData,  receiveItemData, requestItemData} from 'src/app/common/utils/reducer/reducer.utils';

export function roleReducer(
  lastState: IRoleState = new RoleState(),
  action: GenericAction<RoleActionTypes, any>
): IRoleState {
  switch (action.type) {
    
    case RoleActionTypes.REQUEST_PERMISSIONS:
      return requestItemData<IRoleState, IPermissionResponse>(lastState, ['permissions']);
    case RoleActionTypes.RECEIVE_PERMISSIONS:
      return receiveItemData<IRoleState, IPermissionResponse>(lastState, ['permissions'], action.payload);
    case RoleActionTypes.ERROR_RECEIVE_PERMISSIONS:
      return errorItemData<IRoleState, IPermissionResponse>(lastState, ['permissions'], action.payload);

    default:
      return lastState;
  }
}
