import { IStoreApiList, StoreApiList } from 'src/app/common';
import { IBarsChartModel, IDoughnutChartModel } from '../../models/statistics.model';

export interface IDrugCheckState {
  drugCheckData: IStoreApiList<IDoughnutChartModel>;
  drugCheckHistoryData: IStoreApiList<IBarsChartModel>;
}

export class DrugCheckState implements IDrugCheckState {
  drugCheckData: IStoreApiList<IDoughnutChartModel> = new StoreApiList();
  drugCheckHistoryData: IStoreApiList<IBarsChartModel> = new StoreApiList();
}
