import { UserState, IUserState } from './../../../user-management/state/models/user.model';
import { RouterReducerState } from '@ngrx/router-store';
import { INotificationState, NotificationState } from '../notifications/models/notification.model';
import { IRoleState, RoleState } from 'src/app/root/state/role-management/models/role.model';
import { CompanyState, ICompanyState } from 'src/app/companies/state/models/companies.model';
import { IStatisticsState, StatisticsState } from './../../../statistics/state/models/statistics.model';
import { ITestDataAlcotestState, TestDataAlcotestState } from 'src/app/test-data-alcotest/state/models/test-data-alcotest.model';
import { ITestDataDrugCheckState, TestDataDrugCheckState } from 'src/app/test-data-drugcheck/state/models/test-data-drugcheck.model';
import { ActiveDevicesState, IActiveDevicesState } from 'src/app/active-devices/state/models/active-devices.model';

export interface IApplicationState {
  user?: IUserState;
  role: IRoleState;
  companies?: ICompanyState;
  testDataAlcotest?: ITestDataAlcotestState;
  testDataDrugCheck?: ITestDataDrugCheckState;
  activeDevices?: IActiveDevicesState;
  statistics?: IStatisticsState;
  notification: INotificationState;
  router: RouterReducerState;
}

export class ApplicationState {
  user?: IUserState = new UserState();
  role: IRoleState = new RoleState();
  companies?: ICompanyState = new CompanyState();
  testDataAlcotest?: ITestDataAlcotestState = new TestDataAlcotestState();
  testDataDrugCheck?: ITestDataDrugCheckState = new TestDataDrugCheckState();
  activeDevices?: IActiveDevicesState = new ActiveDevicesState();
  statistics?: IStatisticsState = new StatisticsState();
  notification: INotificationState = new NotificationState();
  router: RouterReducerState = null;
}
