import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IPermissionResponse, IRoleState } from '../models/role.model';
import { IStoreApiList } from '../../../../common/models/store-api-list.model';
import { ApplicationState, IApplicationState } from '../../../../common/state/models/app.state.model';

export const roleState = (state: IApplicationState) => state.role;

export const selectPermissions: MemoizedSelector<
  ApplicationState,
  IStoreApiList<IPermissionResponse>,
  DefaultProjectorFn<IStoreApiList<IPermissionResponse>>
> = createSelector(roleState, (state: IRoleState) => state.permissions);