import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { ITableColumn } from '../../models/table.model';
import { ITestDataFields } from 'src/app/test-data-drugcheck/models/test-data-drugcheck.model';

@Injectable({
  providedIn: 'root'
})

export class TableService {

  tableColumns: ITableColumn[];

  public lastTableHeaderElem(tableColumns: ITableColumn[]): string {
    const visibleColumns: ITableColumn[] = tableColumns.filter((field: ITableColumn) => field.visible);

    return visibleColumns[visibleColumns.length - 1]?.field;
  }

  //replace
  public lastTableHeaderElemV2(tableColumns: ITestDataFields[]): string {
    const visibleColumns: ITestDataFields[] = tableColumns.filter((field: ITestDataFields) => field.is_displayed && field.can_be_displayed);

    return visibleColumns[visibleColumns.length - 1]?.key;
  }

  public saveFilters(field: string, value: any, filterName: string, filters: any): void {
    const savedFilters: any = { ...filters };

    savedFilters[field] = value;
    localStorage.setItem(filterName, JSON.stringify(savedFilters));
  }

  public processFilterValues(filters: unknown): any {
    const filtersValue: any = filters;

    for (const property in filtersValue) {
      /* istanbul ignore next */
      if (filtersValue[property] === null || filtersValue[property] === undefined || filtersValue[property] === '') {
        delete filtersValue[property];
      }
    }

    return filtersValue;
  }

  /* istanbul ignore next */
  public arrangePaginingOfElement(table: any): void {
    setTimeout(() => {
      if (table) {
        const tablePages: Element = document.getElementsByClassName('p-paginator-pages')[0];
        const tableAllPagesNumber: Element = document.getElementsByClassName('p-paginator-right-content')[0];

        tablePages?.append(tableAllPagesNumber);
      }
    });
  }

  /* istanbul ignore next */
  public tableDateParameter(date: string, fieldName: string): any {
    return date?.length > 0 ? { [fieldName]: moment.utc(new Date(date)).local().format('YYYY-MM-DDTHH:mm:ss') } : null;
  }

}
