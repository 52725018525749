// eslint-disable-next-line no-shadow
export enum TestDataDrugCheckActionTypes {
  REQUEST_TEST_DATA_DRUGCHECK_PAGE = 'REQUEST_TEST_DATA_DRUGCHECK_PAGE',
  RECEIVE_TEST_DATA_DRUGCHECK_PAGE = 'RECEIVE_TEST_DATA_DRUGCHECK_PAGE',
  ERROR_RECEIVE_TEST_DATA_DRUGCHECK_PAGE = 'ERROR_RECEIVE_TEST_DATA_DRUGCHECK_PAGE',

  REQUEST_TEST_DATA_DRUGCHECK_DETAILS = 'REQUEST_TEST_DATA_DRUGCHECK_DETAILS',
  RECEIVE_TEST_DATA_DRUGCHECK_DETAILS = 'RECEIVE_TEST_DATA_DRUGCHECK_DETAILS',
  ERROR_RECEIVE_TEST_DATA_DRUGCHECK_DETAILS = 'ERROR_RECEIVE_TEST_DATA_DRUGCHECK_DETAILS',

  REQUEST_SECTION_DELETE = 'REQUEST_SECTION_DELETE',
  RECEIVE_SECTION_DELETE = 'RECEIVE_SECTION_DELETE',
  ERROR_RECEIVE_SECTION_DELETE = 'ERROR_RECEIVE_SECTION_DELETE',
  
  REQUEST_UPDATE_LAB_RESULT = 'REQUEST_UPDATE_LAB_RESULT',
  RECEIVE_UPDATE_LAB_RESULT = 'RECEIVE_UPDATE_LAB_RESULT',
  ERROR_UPDATE_LAB_RESULT = 'ERROR_UPDATE_LAB_RESULT',
  RESET_CRUD_UPDATE_LAB_RESULT = 'RESET_CRUD_UPDATE_LAB_RESULT',

  REQUEST_UPDATE_DRUGCHECK_COLUMNS = 'REQUEST_UPDATE_DRUGCHECK_COLUMNS',
  RECEIVE_UPDATE_DRUGCHECK_COLUMNS = 'RECEIVE_UPDATE_DRUGCHECK_COLUMNS',
  ERROR_RECEIVE_UPDATE_DRUGCHECK_COLUMNS = 'ERROR_RECEIVE_UPDATE_DRUGCHECK_COLUMNS',
}
