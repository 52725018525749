import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { importProvidersFrom, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { Action, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ClickOutsideModule } from 'ng-click-outside'
import { CountdownModule } from 'ngx-countdown';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AppHttpInterceptor, CommonModule, HttpHelper, IApplicationState, LoggerService, notificationReducer, WindowService } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { AppComponent } from './../components/app/app.component';
import { AppRoutingModule } from './app-routing.module';
import { ErrorHandlerModule } from './error-handler.module';
import { ContainerComponent } from '../components/container/container.component';
import { LoginComponent } from '../components/login/login.component';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { TabsComponent } from './../components/tabs/tabs.component';
import { routerReducer } from '@ngrx/router-store';
import { DeactivateUserManagementGuard } from 'src/app/user-management/modules/can-deactivate.guard';
import { DeactivateCompaniesManagementGuard } from './../../companies/modules/can-deactivate.guard';
import { RoleService } from '../services/role/role.service';
import { roleReducer } from '../state/role-management/reducers/role.reducer';
import { RoleEffects } from '../state/role-management/effects/role.effects';
import { UserManagementModuleGuard } from 'src/app/user-management/modules/user-management.module.guard';
import { PermissionsService } from '../services/permissions/permissions.service';
import { CompaniesModuleGuard } from 'src/app/companies/modules/companies.module.guard';
import { StatisticsModuleGuard } from 'src/app/statistics/modules/statistics.module.guard';
import { TestDataAlcotestModuleGuard } from 'src/app/test-data-alcotest/modules/test-data-alcotest.module.guard';
import { TestDataDrugCheckModuleGuard } from 'src/app/test-data-drugcheck/modules/test-data-drugcheck.module.guard';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'src/app/common/utils/http-loader.factory';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { ThemingService } from '@odx/angular/theming';
import { OKTA_CONFIG, OktaAuthGuard, OktaAuthModule } from '@okta/okta-angular';
import { oktaAuth } from 'src/app/common/utils/auth.config';
import { ActiveDevicesModuleGuard } from 'src/app/active-devices/modules/active-devices.module.guard';
import { ToastModule } from '@odx/angular/components/toast';
import { RailNavigationModule } from '@odx/angular/components/rail-navigation'
import { ButtonGroupComponent } from '@odx/angular/components/button-group';

@NgModule({ declarations: [
        AppComponent,
        ContainerComponent,
        NavbarComponent,
        LoginComponent,
        TabsComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        ErrorHandlerModule,
        ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
        BrowserAnimationsModule,
        FormsModule,
        CommonModule,
        ClickOutsideModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            connectInZone: true
        }),
        StoreModule.forRoot<IApplicationState, Action>({
            router: routerReducer,
            notification: notificationReducer,
            role: roleReducer,
        }),
        EffectsModule.forRoot([RoleEffects]),
        LoggerModule.forRoot({
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
        }),
        CountdownModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MultiSelectModule,
        TooltipModule,
        OktaAuthModule.forRoot({ oktaAuth }),
        ToastModule,
        RailNavigationModule,
        ButtonGroupComponent], providers: [
        WindowService,
        LoggerService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true,
        },
        {
            provide: OKTA_CONFIG,
            useValue: { oktaAuth },
        },
        importProvidersFrom(OktaAuthModule),
        OktaAuthGuard,
        HttpHelper,
        DeactivateUserManagementGuard,
        DeactivateCompaniesManagementGuard,
        RoleService,
        PermissionsService,
        UserManagementModuleGuard,
        CompaniesModuleGuard,
        StatisticsModuleGuard,
        ActiveDevicesModuleGuard,
        TestDataAlcotestModuleGuard,
        TestDataDrugCheckModuleGuard,
        ThemingService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
