<odx-rail-navigation #railNavigation [isOpen]="false">
  <a
  href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasStatisticsAccess
        )
      "
      [routerLink]="['statistics']"
      routerLinkActive
      iconName="analysis"
      iconSet="safety"
      odxRailNavigationItem 
      ui
      [title]="'statistics' | translate"
    >
      {{ "statistics" | translate }}
</a>
    <a
    href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasAlcotestAccess
        )
      "
      [routerLink]="['test-data-alcotest']"
      routerLinkActive
      odxRailNavigationItem 
      iconName="alcotest-7000-tube"
      iconSet="safety"
      ui
      [title]="'test_data_alcotest' | translate"
    >
      {{ "test_data_alcotest" | translate }}
</a>

    <a
    href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasDrugCheckAccess
        )
      "
      [routerLink]="['test-data-drugcheck']"
      routerLinkActive
      odxRailNavigationItem
      ui
      iconName="drugcheck"
      iconSet="safety"
      [title]="'test_data_drugcheck' | translate"
    >
      {{ "test_data_drugcheck" | translate }}
</a>

    <a 
    href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasDevicesAccess
        )
      "
      [routerLink]="['active-devices']"
      routerLinkActive
      odxRailNavigationItem
      ui
      iconName="group"
      iconSet="safety"
      [title]="'active_devices' | translate"
    >
      {{ "active_devices" | translate }}
    </a>

    <a 
    href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasCompaniesAccess
        )
      "
      [routerLink]="['companies']"
      routerLinkActive
      odxRailNavigationItem
      ui
      iconName="building"
      iconSet="uib-legacy"
       [title]="'companies' | translate"
    >
      {{ "companies" | translate }}
    </a>

    <a 
    href="#"
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasUsersAccess
        )
      "
      [routerLink]="['user-management']"
      routerLinkActive
      odxRailNavigationItem
      ui
      iconName="user-group"
      iconSet="core"
      [title]="'users' | translate"
    >
      {{ "users" | translate }}
    </a>
</odx-rail-navigation>