// eslint-disable-next-line no-shadow
export enum TestDataAlcotestActionTypes {
  REQUEST_TEST_DATA_ALCOTEST_PAGE = 'REQUEST_TEST_DATA_ALCOTEST_PAGE',
  RECEIVE_TEST_DATA_ALCOTEST_PAGE = 'RECEIVE_TEST_DATA_ALCOTEST_PAGE',
  ERROR_RECEIVE_TEST_DATA_ALCOTEST_PAGE = 'ERROR_RECEIVE_TEST_DATA_ALCOTEST_PAGE',

  REQUEST_TEST_DATA_ALCOTEST_FILTERS = 'REQUEST_TEST_DATA_ALCOTEST_FILTERS',
  RECEIVE_TEST_DATA_ALCOTEST_FILTERS = 'RECEIVE_TEST_DATA_ALCOTEST_FILTERS',
  ERROR_RECEIVE_TEST_DATA_ALCOTEST_FILTERS = 'ERROR_RECEIVE_TEST_DATA_ALCOTEST_FILTERS',

  REQUEST_TEST_DATA_ALCOTEST_DETAILS = 'REQUEST_TEST_DATA_ALCOTEST_DETAILS',
  RECEIVE_TEST_DATA_ALCOTEST_DETAILS = 'RECEIVE_TEST_DATA_ALCOTEST_DETAILS',
  ERROR_RECEIVE_TEST_DATA_ALCOTEST_DETAILS = 'ERROR_RECEIVE_TEST_DATA_ALCOTEST_DETAILS',

  REQUEST_SECTION_DELETE = 'REQUEST_SECTION_DELETE',
  RECEIVE_SECTION_DELETE = 'RECEIVE_SECTION_DELETE',
  ERROR_RECEIVE_SECTION_DELETE = 'ERROR_RECEIVE_SECTION_DELETE',

  REQUEST_UPDATE_ALCOTEST_COLUMNS = 'REQUEST_UPDATE_ALCOTEST_COLUMNS',
  RECEIVE_UPDATE_ALCOTEST_COLUMNS = 'RECEIVE_UPDATE_ALCOTEST_COLUMNS',
  ERROR_RECEIVE_UPDATE_ALCOTEST_COLUMNS = 'ERROR_RECEIVE_UPDATE_ALCOTEST_COLUMNS',

}
