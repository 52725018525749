import { Component, Input } from '@angular/core';

@Component({
  selector: 'draeger-sort-icon',
  templateUrl: './sort-icon.component.html',
  styleUrls: ['./sort-icon.component.sass']
})
export class SortIconComponent {
  @Input() columnName: string;
  @Input() columnSorted: any;
}
