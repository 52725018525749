import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'draeger-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.sass'],
})
export class DatePickerComponent implements OnChanges, OnInit {
  @Input() formatDate: string;
  @Input() readOnly: boolean;
  @Input() appendTo: string;
  @Input() showTime: boolean;
  @Input() showSeconds: boolean;
  @Input() selectionMode: string;
  @Input() defaultRange: string | any;
  @Input() minDate: string | Date;
  @Input() maxDate: string | Date;
  @Input() numberOfMonths: number = 1;
  @Input() clearable: boolean = true;
  @Input() disabled: boolean = false;
  @Input() changeToPreviousValue: boolean = false;
  @Input() yearNavigator: boolean = true;
  @Input() monthView: boolean = false;

  @Output() dateSelect: EventEmitter<any> = new EventEmitter();
  @Output() dateChange: EventEmitter<any> = new EventEmitter();

  minDateAsDate: Date;
  maxDateAsDate: Date;

  dateFilters: any;
  placeholder: string;

  showError: boolean = false;
  wrongDate: string;
  editManually: boolean = false;
  previousValue: string | any;


  ngOnInit(): void {
    this.checkDefaultDate();
  }

  ngOnChanges(): void {
    if (!this.editManually) {
      this.checkDefaultDate();
    }

    this.minDateAsDate = new Date(this.minDate);
    this.maxDateAsDate = new Date(this.maxDate);

    this.changePlaceholderFormat();
    this.setMinAndMaxDate();
    this.resetMinAndMaxDate();
  }

  changePlaceholderFormat(): void {
    if (this.selectionMode === 'range') {
      this.placeholder = 'DD/MM/YYYY' + ' - ' + 'DD/MM/YYYY';
    } else if (this.showTime && this.selectionMode !== 'range') {
      this.placeholder = 'DD/MM/YYYY' + ' ' + 'hh:mm:ss';
    } else {
      this.placeholder = 'DD/MM/YYYY';
    }

    if ((this.showTime && this.showSeconds) && this.selectionMode === 'range') {
      this.placeholder = this.formatDate?.replace('MM', 'mm') + ' ' + 'hh:mm:ss' + ' - ' + this.formatDate?.replace('MM', 'mm') + ' ' + 'hh:mm:ss';
    }
  }

  setMinAndMaxDate(): void {
    if (!this.minDate) {
      this.minDate = new Date(2000, 1, 1);
      this.minDateAsDate = new Date(this.minDate);
    }

    if (!this.maxDate) {
      this.maxDate = new Date(2030, 1, 1);
      this.maxDateAsDate = new Date(this.maxDate);
    }
  }

  resetMinAndMaxDate(): void {
    if (new Date(this.minDate).getFullYear() === 1970 && new Date(this.maxDate).getFullYear() === 1970) {
      this.minDate = new Date(2000, 1, 1);
      this.maxDate = new Date(2030, 1, 1);

      this.minDateAsDate = new Date(this.minDate);
      this.maxDateAsDate = new Date(this.maxDate);

      this.dateFilters = '';
    }
  }

  checkDefaultDate(): void {
    if (moment(this.defaultRange, moment.ISO_8601, true).isValid()) {
      this.dateFilters = new Date(this.defaultRange);
      this.previousValue = new Date(this.defaultRange);
      if (this.monthView) {
        const splitDate = this.dateFilters?.split('/');
        if (splitDate.length > 2) {
          const year = splitDate[2];
          const month = splitDate[1];
          this.dateFilters = month + '/' + year;
        } else {
          const year = splitDate[1];
          const month = splitDate[0];
          this.dateFilters = month + '/' + year;
        }
      }
    } else if (this.defaultRange && this.defaultRange[0]) {
      this.dateFilters = this.defaultRange;
      this.previousValue = this.defaultRange;
      if (this.monthView) {
        const splitDate = this.dateFilters?.split('/');
        if (splitDate.length > 2) {
          const year = splitDate[2];
          const month = splitDate[1];
          this.dateFilters = month + '/' + year;
        } else {
          const year = splitDate[1];
          const month = splitDate[0];
          this.dateFilters = month + '/' + year;
        }
      }
    } else {
      this.dateFilters = '';
    }


  }

  getTheDateSeparator(): string {
    let dateSeparator: string = '/';

    if (this.formatDate.includes('.')) {
      dateSeparator = '.';
    }

    return dateSeparator;
  }

  onSelect(): void {
    this.previousValue = this.dateFilters;
    this.dateSelect.emit(this.dateFilters);
  }

  onChange(event: any) {
    this.dateChange.emit(event);
  }

  resetRange(): void {
    this.dateFilters = '';

    if (!this.changeToPreviousValue) {
      this.previousValue = this.dateFilters;
    }

    this.dateChange.emit(this.dateFilters);
  }

  editManuallyStart(event: any): void {
    this.editManually = true;

    if (event.target.value.length < 1) {
      this.resetRange();
    }

    if (event.target.value.length > 0) {

      if (event.target.value.includes('-')) {
        this.errorHandlerForRangeDate(event);
      } else {
        this.errorHandlerForSingleDate(event);
      }
    }
  }

  errorHandlerForRangeDate(event: any): void {
    const dateRangeToArr: any[] = event.target.value.split('-');
    let startDateArr: any[] = dateRangeToArr[0].split(this.getTheDateSeparator());

    startDateArr = [startDateArr[1], startDateArr[0], startDateArr[2]];
    const formatedStartDate: string = startDateArr.toString().replace(',', this.getTheDateSeparator());

    let endDateArr: any[] = dateRangeToArr[1].split(this.getTheDateSeparator());

    endDateArr = [endDateArr[1], endDateArr[0], endDateArr[2]];
    const formatedEndDate: string = endDateArr.toString().replace(',', this.getTheDateSeparator());

    if (
      new Date(formatedStartDate).toString() === 'Invalid Date' ||
      new Date(formatedEndDate).toString() === 'Invalid Date'
    ) {
      this.showError = true;
    } else {
      this.showError = false;
    }

  }

  errorHandlerForSingleDate(event: any): void {
    let dateToArr: any[] = event.target.value.split(this.getTheDateSeparator());

    dateToArr = [dateToArr[1], dateToArr[0], dateToArr[2]];
    const newDate: string = dateToArr.toString().replace(',', this.getTheDateSeparator());

    if (new Date(newDate).toString() === 'Invalid Date') {
      this.showError = true;
    } else {
      this.showError = false;
    }
  }

  checkIfDateIsInvalid(event: any): void {
    this.wrongDate = event.target.value;

    if (this.dateFilters === null) {
      this.addPreviousValue();
    } else if (this.editManually) {
      this.checkIfDateIsValid();
    }

    if (this.wrongDate.length < 1) {
      this.showError = false;
    }

    this.editManually = false;
  }

  addPreviousValue(): void {
    this.showError = true;

    if (Array.isArray(this.previousValue)) {
      this.dateFilters = this.previousValue;
      this.dateSelect.emit(this.dateFilters);
      this.showError = false;
    }

    if (!this.previousValue) {
      this.showError = false;
    }

    if (typeof (this.previousValue) === 'object') {
      this.dateSelect.emit(this.previousValue);
    } else if (typeof (this.previousValue) !== 'object' && !this.previousValue?.includes('-')) {
      if (this.previousValue) {
        this.dateSelect.emit(this.previousValue);
      } else {
        this.resetRange();
      }
    } else {
      this.formatPreviousDateRange();
    }

    this.showError = false;
  }

  formatPreviousDateRange(): void {
    const dateRangeToArr: any[] = this.previousValue.split('-');
    let startDateArr: any[] = dateRangeToArr[0].split(this.getTheDateSeparator());

    startDateArr = [startDateArr[1], startDateArr[0], startDateArr[2]];
    const formatedStartDate: string = startDateArr.toString().replace(',', this.getTheDateSeparator());

    let endDateArr: any[] = dateRangeToArr[1].split(this.getTheDateSeparator());

    endDateArr = [endDateArr[1], endDateArr[0], endDateArr[2]];
    const formatedEndDate: string = endDateArr.toString().replace(',', this.getTheDateSeparator());

    this.previousValue = [new Date(formatedStartDate), new Date(formatedEndDate)];
    this.dateSelect.emit(this.previousValue);
  }

  checkIfDateIsValid(): void {
    this.returnPreviousValueIfSelectedRangeIsLessThanMinValue();
    this.resetOrEmitPreviousValue();

    this.showError = false;
  }

  returnPreviousValueIfSelectedRangeIsLessThanMinValue(): void {
    if (
      (new Date(this.dateFilters[0]).getTime() < new Date(this.minDate).getTime() || new Date(this.dateFilters[1]).getTime() > new Date(this.maxDate).getTime())
      && this.selectionMode === 'range'
    ) {
      this.dateFilters = this.previousValue;
      this.dateSelect.emit(this.dateFilters);
    }
  }

  resetOrEmitPreviousValue(): void {
    if (Array.isArray(this.dateFilters)) {
      this.dateSelect.emit(this.dateFilters);
    }

    if (typeof (this.dateFilters) === 'object') {
      this.dateSelect.emit(this.dateFilters);
    }

    if (typeof (this.dateFilters) === 'string' && this.dateFilters.length < 1) {
      if (this.changeToPreviousValue) {
        this.dateSelect.emit(this.previousValue);
      } else {
        this.resetRange();
      }
    }
  }

}
