import { HttpHeaderResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class HttpHelper {
  get options(): HttpHeaderResponse {
    return new HttpHeaderResponse({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    });
  }

}
