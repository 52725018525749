import { IStoreApiItem, StoreApiItem } from "src/app/common";
import { IActiveDevicesPage,ICalibrationAccuracyPage } from "../../models/active-devices.model";

export interface IActiveDevicesState {
  activeDevicesPage: IStoreApiItem<IActiveDevicesPage>;
  activeDevicesDetails: IStoreApiItem<ICalibrationAccuracyPage>;
}

export class ActiveDevicesState implements IActiveDevicesState {
  activeDevicesPage: IStoreApiItem<IActiveDevicesPage> = new StoreApiItem();
  activeDevicesDetails: IStoreApiItem<ICalibrationAccuracyPage> = new StoreApiItem();
}