import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITestDataDrugCheckPage } from 'src/app/test-data-drugcheck/models/test-data-drugcheck.model';
import { TestDataDrugCheckActionTypes } from '../test-data-drugcheck-action-types';

export class RequestTestDataDrugCheckPage implements Action {
  readonly type: TestDataDrugCheckActionTypes.REQUEST_TEST_DATA_DRUGCHECK_PAGE = TestDataDrugCheckActionTypes.REQUEST_TEST_DATA_DRUGCHECK_PAGE;

  constructor(public payload?: any) { }
}
export class ReceiveTestDataDrugCheckPage implements Action {
  readonly type: TestDataDrugCheckActionTypes.RECEIVE_TEST_DATA_DRUGCHECK_PAGE = TestDataDrugCheckActionTypes.RECEIVE_TEST_DATA_DRUGCHECK_PAGE;

  constructor(public payload: ITestDataDrugCheckPage | any) { }
}
export class ErrorReceiveTestDataDrugCheckPage implements Action {
  readonly type: TestDataDrugCheckActionTypes.ERROR_RECEIVE_TEST_DATA_DRUGCHECK_PAGE = TestDataDrugCheckActionTypes.ERROR_RECEIVE_TEST_DATA_DRUGCHECK_PAGE;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUpdateDrugCheckColumns implements Action {
  readonly type: TestDataDrugCheckActionTypes.REQUEST_UPDATE_DRUGCHECK_COLUMNS = TestDataDrugCheckActionTypes.REQUEST_UPDATE_DRUGCHECK_COLUMNS;

  constructor(public payload: any) { }
}
export class ReceiveUpdateDrugCheckColumns implements Action {
  readonly type: TestDataDrugCheckActionTypes.RECEIVE_UPDATE_DRUGCHECK_COLUMNS = TestDataDrugCheckActionTypes.RECEIVE_UPDATE_DRUGCHECK_COLUMNS;

  constructor(public payload: any) { }
}
export class ErrorReceiveUpdateDrugCheckColumns implements Action {
  readonly type: TestDataDrugCheckActionTypes.ERROR_RECEIVE_UPDATE_DRUGCHECK_COLUMNS = TestDataDrugCheckActionTypes.ERROR_RECEIVE_UPDATE_DRUGCHECK_COLUMNS;

  constructor(public payload: HttpErrorResponse) { }
}
