import { CommonConstants } from "src/app/common/constants/common.constants";

export interface INotificationState {
  type: NotificationTypes;
  message: string;
  value?: string;
  category?: string;
}

export class NotificationState implements INotificationState {
  type: NotificationTypes = null;
  message: string = null;
  value?: string = null;
  category?: string = null;
}

const { SUCCESS, ERROR, WARNING } = CommonConstants.notificationType;

export type NotificationTypes = typeof SUCCESS | typeof ERROR | typeof WARNING | '';
