import { IStoreApiList, StoreApiList } from 'src/app/common/models/store-api-list.model';
import { IBarsChartModel, IDoughnutChartModel } from '../../models/statistics.model';

export interface IAlcotestState {
  alcotestData: IStoreApiList<IDoughnutChartModel>;
  alcotestHistoryData: IStoreApiList<IBarsChartModel>;
}

export class AlcotestState implements IAlcotestState {
  alcotestData: IStoreApiList<IDoughnutChartModel> = new StoreApiList();
  alcotestHistoryData: IStoreApiList<IBarsChartModel> = new StoreApiList();
}
