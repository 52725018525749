<odx-area-header [size]="headerSize" class="c-user-menu">
  <odx-avatar>{{ userInitials }} </odx-avatar>
  <span class="odx-fs-normal odx-title-5">{{ userName }}</span>
  <odx-area-header-subtitle> {{ userEmail }} </odx-area-header-subtitle>

  <odx-area-header-content class="c-user-menu__content">
    <odx-logo [size]="logoSize" [variant]="variant"></odx-logo>
    <a odxLink class="c-user-menu__logout" (click)="handleLogoutClick()">{{'logout' | translate}}</a>
  </odx-area-header-content>
</odx-area-header>
