import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from '../../models/app-types';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { IPermissionResponse } from '../../state/role-management/models/role.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'draeger-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent extends OnDestroyMixin() implements OnInit {
  @Input() permissions: IPermissionResponse;
  appUserPermissionList: any = AppUserPermissionList;

  constructor(
    public permissionService: PermissionsService,
    public router: Router,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const hasStatisticsAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasStatisticsAccess);
    const hasDrugCheckAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasDrugCheckAccess);
    const hasAlcotestAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasAlcotestAccess);

    this.translate.onLangChange.subscribe()
    if (hasStatisticsAccess && this.router.url === '/') {
      this.router.navigate(['statistics']);
    } else if (!hasStatisticsAccess && hasAlcotestAccess && this.router.url === '/') {
      this.router.navigate(['test-data-alcotest']);
    } else if (!hasStatisticsAccess && hasDrugCheckAccess && this.router.url === '/') {
      this.router.navigate(['test-data-drugcheck']);
    }

  }

}
