import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { IPermissionResponse, IRolePermission } from '../models/role.model';
import { RoleActionTypes } from './role-action-types';

export class RequestPermissions implements Action {
  readonly type: RoleActionTypes.REQUEST_PERMISSIONS = RoleActionTypes.REQUEST_PERMISSIONS;
}

export class ReceivePermissions implements Action {
  readonly type: RoleActionTypes.RECEIVE_PERMISSIONS = RoleActionTypes.RECEIVE_PERMISSIONS;

  constructor(public payload: IPermissionResponse) { }
}

export class ErrorReceivePermissions implements Action {
  readonly type: RoleActionTypes.ERROR_RECEIVE_PERMISSIONS = RoleActionTypes.ERROR_RECEIVE_PERMISSIONS;

  constructor(public payload: HttpErrorResponse) { }
}
