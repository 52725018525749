import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  public toDegreesMinutesAndSeconds(coordinate: number, integerSeconds?: boolean, showSeconds?: boolean): string {
    const absolute: number = Math.abs(coordinate);
    const degrees: number = Math.floor(absolute);
    const minutesNotTruncated: number = (absolute - degrees) * 60;
    const minutes: number = Math.floor(minutesNotTruncated);
    const seconds: string = integerSeconds ? ((minutesNotTruncated - minutes) * 60).toFixed(0) : ((minutesNotTruncated - minutes) * 60).toFixed(2);

    return showSeconds ? `${degrees}° ${minutes}' ${seconds}"` : `${degrees}° ${minutes}'`;
  }

  public convertToDMS(lat: number, lng: number, integerSeconds?: boolean, showSeconds?: boolean): string {

    if (lat !== undefined && lng !== undefined) {

      const latitude: string = this.toDegreesMinutesAndSeconds(lat, integerSeconds, showSeconds);
      const latitudeCardinal: string = Math.sign(lat) >= 0 ? 'N' : 'S';

      const longitude: string = this.toDegreesMinutesAndSeconds(lng, integerSeconds, showSeconds);
      const longitudeCardinal: string = Math.sign(lng) >= 0 ? 'E' : 'W';

      return `${latitude} ${latitudeCardinal}, ${longitude} ${longitudeCardinal}`;
    }

  }
}
