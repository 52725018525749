import { HttpErrorResponse } from "@angular/common/http";
import { Action } from '@ngrx/store';
import { ITestDataAlcotestDetails } from "src/app/test-data-alcotest/models/test-data-alcotest.model";
import { TestDataAlcotestActionTypes } from "../test-data-alcotest-action-types";

export class RequestTestDataAlcotestDetails implements Action {
  readonly type: TestDataAlcotestActionTypes.REQUEST_TEST_DATA_ALCOTEST_DETAILS = TestDataAlcotestActionTypes.REQUEST_TEST_DATA_ALCOTEST_DETAILS;

  constructor(public payload?: any) { }
}
export class ReceiveTestDataAlcotestDetails implements Action {
  readonly type: TestDataAlcotestActionTypes.RECEIVE_TEST_DATA_ALCOTEST_DETAILS = TestDataAlcotestActionTypes.RECEIVE_TEST_DATA_ALCOTEST_DETAILS;

  constructor(public payload: ITestDataAlcotestDetails) { }
}
export class ErrorReceiveTestDataAlcotestDetails implements Action {
  readonly type: TestDataAlcotestActionTypes.ERROR_RECEIVE_TEST_DATA_ALCOTEST_DETAILS = TestDataAlcotestActionTypes.ERROR_RECEIVE_TEST_DATA_ALCOTEST_DETAILS;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestSectionDelete implements Action {
  readonly type: TestDataAlcotestActionTypes.REQUEST_SECTION_DELETE = TestDataAlcotestActionTypes.REQUEST_SECTION_DELETE;

  constructor(public payload: any) { }
}
export class ReceiveSectionDelete implements Action {
  readonly type: TestDataAlcotestActionTypes.RECEIVE_SECTION_DELETE = TestDataAlcotestActionTypes.RECEIVE_SECTION_DELETE;

  constructor(public payload: any) { }
}
export class ErrorReceiveSectionDelete implements Action {
  readonly type: TestDataAlcotestActionTypes.ERROR_RECEIVE_SECTION_DELETE = TestDataAlcotestActionTypes.ERROR_RECEIVE_SECTION_DELETE;

  constructor(public payload: HttpErrorResponse) { }
}
