import { IStoreApiList, StoreApiList } from "src/app/common";
import { IDoughnutChartModel } from "../../models/statistics.model";


export interface ITestsPerCompanyState {
  testsPerCompanyData: IStoreApiList<any>;
}

export class TestsPerCompanyState implements ITestsPerCompanyState {
  testsPerCompanyData: IStoreApiList<any> = new StoreApiList();
}