<odx-header>
  <button odxButton odxMainMenuButton>
    <odx-icon name="menu"></odx-icon>
  </button>
  <odx-action-group>
    <button odxButton size="large" (click)="navigateToDashboard()">
      <odx-icon name="home" size="large"></odx-icon>
    </button>
    <button
      odxButton
      size="large"
      [odxMenu]="menu"
      [odxMenuPosition]="'bottom-end'"
    >
      <odx-icon name="settings" size="large"></odx-icon>
    </button>
  </odx-action-group>
  <button odxButton odxHeaderAvatar [odxDropdown]="content">
    <odx-avatar>
      @if(userInitials) {
      {{ userInitials }}
      }@else {
      <odx-icon name="user"></odx-icon>
      }
    </odx-avatar>
  </button>
</odx-header>
<ng-template #menu>
  <odx-menu>
    <button
      odxButton
      [odxMenu]="languagesMenu"
      [odxMenuPosition]="'bottom-end'"
    >
      {{ "languages" | translate }}
    </button>
  </odx-menu>
</ng-template>

<ng-template #languagesMenu>
  <odx-menu>
    <button odxButton odxMenuItem (click)="selectLanguage('en-GB')">
      {{ "english" | translate }}
    </button>
    <button odxButton odxMenuItem (click)="selectLanguage('de-DE')">
      {{ "german" | translate }}
    </button>
    <button odxButton odxMenuItem (click)="selectLanguage('fr-FR')">
      {{ "french" | translate }}
    </button>
    <button odxButton odxMenuItem (click)="selectLanguage('es-ES')">
      {{ "spanish" | translate }}
    </button>
    <button odxButton odxMenuItem (click)="selectLanguage('pl-PL')">
      {{ "polish" | translate }}
    </button>
  </odx-menu>
</ng-template>

<odx-main-menu title="Dräger" copyright="© Drägerwerk AG & Co. KGaA 2024">
  <a odxMainMenuItem (click)="handlePrivacyPolicyClick()">
    {{ "privacy_policy" | translate }}
  </a>
  <a odxMainMenuItem (click)="handleTermsAndConditionsClick()">
    {{ "terms_conditions" | translate }}
  </a>
</odx-main-menu>
