import { IStoreApiList, StoreApiList } from 'src/app/common';
import { ITestsOnMapModel } from '../../models/statistics.model';

export interface ITestsOnMapState {
  testsOnMapData: IStoreApiList<ITestsOnMapModel>;
}

export class TestsOnMapState implements ITestsOnMapState {
  testsOnMapData: IStoreApiList<ITestsOnMapModel> = new StoreApiList();
}
