<div class="test-data__content-right-column-container" *ngIf="data">
  <div class="test-data__results-header" *ngIf="data?.status !== undefined">
    <div class="test-data__results-header-title">
      <strong>{{ "status" | translate }}:</strong>
    </div>
    <div
      class="test-data__results-dot"
      [ngStyle]="{
        background: data?.status_color
      }"
    ></div>
    <div class="test-data__results-card-header-status">
      {{ data?.status ? (data?.status | translate) : "-" }}
    </div>
  </div>
  <hr class="divider" />

  <div
    class="test-data__results-subtitle"
    *ngIf="data?.screening_results !== undefined"
  >
    <div>
      <div>{{ "screening" | translate }}</div>
      <div>
        {{
          data?.screening_results?.analysis_date
            ? (data?.screening_results?.analysis_date
              | date : "dd/MM/YYYY - HH:mm")
            : "-"
        }}
      </div>
    </div>
  </div>

  <div class="test-data__results-table" *ngIf="data?.screening_results">
    <div
      class="test-data__results-table-container"
      *ngFor="let screeningResult of screeningResults"
    >
      <div class="test-data__results-table-header">
        {{ screeningResult.name.slice(-3) | translate }}
      </div>
      <div class="test-data__table-content-flag" *ngIf="screeningResult.edited">
        {{ "manually_edited" | translate }}
      </div>
      <div class="test-data__results-table-row">
        <div class="test-data__results-table-row-status">
          <div
            *ngIf="screeningResult.status === 'detected'"
            class="test-data__results-dot test-data__results-dot--red"
          ></div>
          {{ screeningResult.status | translate }}
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isVisibleLabResults; else editLabResults">
    <div
      class="test-data__results-subtitle"
      *ngIf="data?.lab_results !== undefined"
    >
      <div>
        <div>{{ "lab_analysis_date" | translate }}</div>
        <div>
          {{
            data?.lab_results?.analysis_date
              ? (data?.lab_results?.analysis_date | date : "dd/MM/YYYY - HH:mm")
              : "-"
          }}
        </div>
      </div>

      <button
        odxButton
        variant="secondary"
        type="button"
        (click)="onEditLabResults()"
        *ngIf="data.can_edit_lab_results"
        style="background-color: #3a4c61"
      >
        {{ "edit_results" | translate }}
      </button>
    </div>

    <div class="test-data__results-table" *ngIf="data?.lab_results">
      <div
        class="test-data__results-table-container"
        *ngFor="let labResult of labResults"
      >
        <div class="test-data__results-table-header">
          {{ labResult.name.slice(-3) | translate }}
        </div>
        <div class="test-data__table-content-flag" *ngIf="labResult.edited">
          {{ "manually_edited" | translate }}
        </div>
        <div class="test-data__results-table-row">
          <div class="test-data__results-table-row-status">
            <div
              *ngIf="labResult.status === 'detected'"
              class="test-data__results-dot test-data__results-dot--red"
            ></div>
            <div>{{ labResult.status | translate }}</div>
          </div>
          <div
            *ngIf="
              labResult.concentration &&
              labResult.concentration !== '0.000 ng/ml' &&
              labResult.concentration !== '0 ng/ml'
            "
            class="test-data__results-detected"
          >
            {{ labResult.concentration | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #editLabResults>
    <div
      class="test-data__results-subtitle"
      *ngIf="data?.lab_results !== undefined"
    >
      <div>
        <div>{{ "lab_analysis_date" | translate }}</div>

        <div class="mt-2">
          <draeger-datepicker
            [appendTo]="'body'"
            [selectionMode]="'single'"
            [formatDate]="'dd.mm.yyyy'"
            [clearable]="false"
            [defaultRange]="defaultLabAnalysis.analysis_date"
            (dateSelect)="onDateChange($event)"
          >
          </draeger-datepicker>
        </div>
      </div>

      <button
        odxButton
        variant="highlight"
        type="button"
        (click)="onSaveLabResults()"
        *ngIf="data.can_edit_lab_results"
        [disabled]="errorMessages.length !== 0"
      >
        {{ "save" | translate }}
      </button>
    </div>

    <div class="test-data__results-table">
      <div
        class="test-data__results-table-container"
        *ngFor="let labResult of labResults; let i = index"
      >
        <div>
          <div>
            <div
              class="test-data__results-table-header test-data__results-table-header--large"
            >
              <div>{{ labResult?.name.slice(-3) | translate }}</div>

              <div
                class="textfield"
                (mouseover)="
                  showTooltip = {
                    field: 'concentration_' + labResult?.name.split('_')[1],
                    value: true
                  }
                "
                (mouseout)="
                  showTooltip = {
                    field: 'concentration_' + labResult?.name.split('_')[1],
                    value: false
                  }
                "
              >
                <input
                  [ngClass]="{
                    'p-input--error':
                      defaultLabAnalysis[
                        'concentration_' + labResult?.name.split('_')[1]
                      ]
                        ?.toString()
                        .split('.')[0].length > 5
                  }"
                  (debounce)="
                    onInputChange(
                      $event,
                      'concentration_' + labResult?.name.split('_')[1]
                    )
                  "
                  [delay]="600"
                  type="string"
                  onkeydown="return event.keyCode !== 69"
                  step="any"
                  [(ngModel)]="
                    this.defaultLabAnalysis[
                      'concentration_' + labResult?.name.split('_')[1]
                    ]
                  "
                  draegerInputDebounce
                  class="p-input"
                />
                <span class="p-placeholder">
                  {{ labResult?.concentration?.split(" ")[1] }}
                </span>
                <div *ngFor="let errorMessage of errorMessages">
                  <div
                    *ngIf="
                      errorMessage?.field ===
                        'concentration_' + labResult?.name.split('_')[1] &&
                      showTooltip?.field === errorMessage?.field &&
                      showTooltip?.value
                    "
                    data-tooltip-visible
                    [attr.data-tooltip]="'decimal_digits_error' | translate"
                    data-tooltip-pos="up"
                  ></div>
                </div>
              </div>
            </div>

            <div
              class="test-data__results-table-row test-data__results-table-row--large"
            >
              <div class="modal-form">
                <p-dropdown
                  ngDefaultControl
                  optionLabel="value"
                  optionValue="value"
                  [placeholder]="'-'"
                  [options]="statusValues"
                  [(ngModel)]="labResult.status"
                  draegerInputDebounce
                  (debounce)="
                    onDropdownChange($event, labResult?.name.split('_')[1])
                  "
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{ option.value | translate }} </span>
                  </ng-template>
                  <ng-template let-option pTemplate="selectedItem">
                    <span>{{ option.value | translate }} </span>
                  </ng-template>
                </p-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
