import { HttpErrorResponse } from '@angular/common/http';

export interface IStoreApiItem<T> {
  isLoading: boolean;
  data: T;
  errors: HttpErrorResponse;
  isSuccess: boolean;
}

export class StoreApiItem<T> implements IStoreApiItem<T> {
  isLoading: boolean = false;
  data: T = null;
  errors: HttpErrorResponse = null;
  isSuccess: boolean = false;
}
