import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  inject,
} from '@angular/core';
import { GoogleMap, MapInfoWindow } from '@angular/google-maps';
import { take } from 'rxjs/operators';
import {
  CommonConstants,
  MapService,
  NotificationActions,
} from 'src/app/common';
import * as darkMapTheme from '../../../../assets/styles/dark-theme.js';
import { mapOptions } from './mapOptions';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';
import { TranslateService } from '@ngx-translate/core';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { Loader } from '@googlemaps/js-api-loader';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'draeger-maps-coordinates',
  templateUrl: './maps-coordinates.component.html',
  styleUrls: ['./maps-coordinates.component.sass'],
})
export class MapsCoordinatesComponent
  extends OnDestroyMixin()
  implements OnInit {
  isMapPopupShown: boolean = false;
  marker: any = {};
  darkTheme: any = darkMapTheme.style;
  defaultCoordinates: any = { lat: 53.8578205, lng: 10.6905946 };
  markerOptions: google.maps.MarkerOptions = {
    icon: '/assets/img/default-marker.svg',
  };
  mapOptions: google.maps.MapOptions;
  mapApiLoaded: boolean = false;
  @ViewChild('confirmLocationModalTemplate', { read: TemplateRef })
  public confirmLocationModalTemplate: TemplateRef<any>;
  confirmLocationModalReference: ModalRef<any>;
  private readonly modalService = inject(ModalService);

  @ViewChild(GoogleMap) map!: GoogleMap | any;
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow;

  @Input() selectedCoordinates: string | any;
  @Output() handleLocation: EventEmitter<google.maps.Marker[]> =
    new EventEmitter();

  constructor(
    public notificationActions: NotificationActions,
    public mapService: MapService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.loadMap();
    this.mapOptions = mapOptions;
    this.mapOptions.styles = this.darkTheme.styles;
    this.addDefaultMarker();
  }

  addDefaultMarker() {
    const selectedCoordinatesEmpty: boolean = this.selectedCoordinates
      ? Object.keys(this.selectedCoordinates).length === 0
      : true;
    const savedLocationFilterInLocalStorage: boolean = this.savedLocationFilter?.position
      ? Object.keys(this.savedLocationFilter.position).length !== 0
      : false;

    /* istanbul ignore next */
    if (selectedCoordinatesEmpty) {
      if (savedLocationFilterInLocalStorage) {
        this.mapOptions.center = this.savedLocationFilter.position;
        this.marker = { position: this.savedLocationFilter.position };
      } else {
        this.mapOptions.center = this.defaultCoordinates;
        this.marker = { position: this.defaultCoordinates };
      }
    } else {
      this.mapOptions.center = this.selectedCoordinates;
      this.marker = { position: this.selectedCoordinates };
    }

    this.mapOptions.zoom = this.savedLocationFilter?.zoom
      ? this.savedLocationFilter?.zoom
      : 14;
  }

  addMarker(position: any) {
    this.marker = {
      position: { lat: position?.latLng?.lat(), lng: position?.latLng?.lng() },
    };
  }

  /* istanbul ignore next */
  onOpenMapClick(e: Event): void {
    e.stopPropagation();
    this.addDefaultMarker();
    this.isMapPopupShown = true;

    this.confirmLocationModalReference = this.modalService.open(
      this.confirmLocationModalTemplate,
      {
        size: 'medium',
        variant: 'default',
        dismissable: false,
      }
    );

    this.confirmLocationModalReference.onClose$.subscribe(() => {
      this.isMapPopupShown = false;
    });

    this.confirmLocationModalReference.onClose$
      .pipe(take(1))
      .subscribe((isLocationConfirmed: boolean) => {
        if (isLocationConfirmed) {
          this.handleLocation.emit(this.marker.position);
          this.isMapPopupShown = false;
        }
      });
  }

  resetCoordinates(): void {
    this.marker.position = {};
    this.handleLocation.emit(this.marker.position);
  }

  mapErrorHandling(): void {
    this.notificationActions.requestShowNotification(
      CommonConstants.notificationType.ERROR,
      this.translate.instant("map_error")
    );
  }

  get savedLocationFilter(): any {
    return localStorage.getItem('locationFilter')
      ? JSON.parse(localStorage.getItem('locationFilter'))
      : null;
  }

  loadMap() {
    let loader = new Loader({
      apiKey: environment.GOOGLE_MAP.GOOGLE_API_KEY
    })
    loader.importLibrary('maps').then(() => {
      this.mapApiLoaded = true
    })
  }
}
