import { Component, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';


@Component({
  selector: 'draeger-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.sass']
})
export class ContainerComponent implements OnDestroy {

  @Input() permissions: string[];
  @Input() userLoggedIn: boolean;
  @Input() userUnauthorized: boolean;
  readonly destroy: Subject<void> = new Subject<void>();

  constructor(
    public router: Router
  ) { }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
