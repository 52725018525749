import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { LoggerMonitor } from './logger-monitor';
import { CommonConstants } from '../../constants/common.constants';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {

  constructor(private logger: NGXLogger) {
    this.logger.registerMonitor(new LoggerMonitor());
  }

  logMessage(message: any, type?: string): void {
    switch (type) {
      case CommonConstants.logType.WARNING:
        this.logger.warn(message);
        break;
      case CommonConstants.logType.ERROR:
        this.logger.error(message);
        break;
      case CommonConstants.logType.INFO:
        this.logger.info(message);
        break;
      default:
        this.logger.debug(message);
    }
  }

  /* istanbul ignore next */
  logException(error: Error, severityLevel?: number): void {
    if (window.location.port !== '9876') {
      if (error instanceof Error) {
        this.logger.error({ exception: error, severityLevel });
      } else {
        this.logger.error({ exception: new Error(error), severityLevel });
      }
    }
  }
}
