<div class="c-root">
  <odx-header />
  <main role="main" class="main-login">
    <div class="c-wizard-login">
      <div class="c-wizard-login__container">
        <div class="c-wizard-login__title">
          {{ "login_welcome" | translate }}
        </div>
        <div class="c-wizard-login__body">
          <button
            class="c-wizard-login__button"
            odxButton
            size="medium"
            variant="highlight"
            (click)="signIn()"
          >
            {{ "login" | translate }}
          </button>
        </div>
        <div *ngIf="errors" class="error-login">
          {{ "login_error" | translate }} <br />
          {{ errors | translate }}
        </div>
      </div>
    </div>
  </main>
</div>
