/* istanbul ignore next */

import { HttpErrorResponse } from '@angular/common/http';
import { IStoreApiItem } from './../../models/store-api-item.model';
import { IStoreApiList } from './../../models/store-api-list.model';

const getStateWithUpdatedPart: <StateType, UpdatedType>(
  lastState: StateType,
  pathToUpdate: string[],
  updatedPathState: UpdatedType
) => StateType = <StateType, UpdatedType>(
  lastState: StateType,
  pathToUpdate: string[],
  updatedPathState: UpdatedType
) => {
    switch (pathToUpdate.length) {
      case 1: {
        return { ...lastState, [pathToUpdate[0]]: { ...lastState[pathToUpdate[0]], ...updatedPathState } };
      }

      case 2: {
        return {
          ...lastState,
          [pathToUpdate[0]]: {
            ...lastState[pathToUpdate[0]],
            [pathToUpdate[1]]: {
              ...lastState[pathToUpdate[0]][pathToUpdate[1]],
              ...updatedPathState,
            },
          },
        };
      }

      case 3: {
        return {
          ...lastState,
          [pathToUpdate[0]]: {
            ...lastState[pathToUpdate[0]],
            [pathToUpdate[1]]: {
              ...lastState[pathToUpdate[0]][pathToUpdate[1]],
              [pathToUpdate[2]]: {
                ...lastState[pathToUpdate[0]][pathToUpdate[1]][pathToUpdate[2]],
                ...updatedPathState,
              },
            },
          },
        };
      }

      /* istanbul ignore next */
      default:
        throw new Error(
          'getStateWithUpdatedPart doesn\'t supports only 1, 2 or 3 levels deep. Extend the method to get more.'
        );
    }
  };

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const requestListData: <StateType, ListType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ListType
>(
  lastState: StateType,
  pathToUpdate: string[]
) => {
  const updatedPathState: IStoreApiList<ListType> = {
    isLoading: true,
    errors: null,
    data: null,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

/* istanbul ignore next */
export const receiveListData: <StateType, ListType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ListType
) => StateType = <StateType, ListType>(lastState: StateType, pathToUpdate: string[], data: ListType) => {
  const updatedPathState: IStoreApiList<ListType> = { isLoading: false, errors: null, data };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const errorListData: <StateType, ListType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: HttpErrorResponse
) => StateType = <StateType, ListType>(lastState: StateType, pathToUpdate: string[], errors: HttpErrorResponse) => {
  const updatedPathState: IStoreApiList<ListType> = { ...lastState, isLoading: false, errors, data: null };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const requestItemData: <StateType, ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType
>(
  lastState: StateType,
  pathToUpdate: string[]
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    isLoading: true,
    errors: null,
    data: null,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

/* istanbul ignore next */
export const receiveItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ItemType
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = { isLoading: false, errors: null, data };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const errorItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: HttpErrorResponse
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: HttpErrorResponse) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    errors,
    data: null,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const requestPostPutItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ItemType
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = data
    ? { isLoading: true, errors: null, data, isSuccess: false }
    : { isLoading: true, errors: null, isSuccess: false };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

/* istanbul ignore next */
export const receivePostPutItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  data: ItemType
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], data: ItemType) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = data
    ? { isLoading: false, errors: null, data, isSuccess: true }
    : { isLoading: false, errors: null, isSuccess: true };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const errorPostPutItemData: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  errors: HttpErrorResponse
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: HttpErrorResponse) => {
  const updatedPathState: IStoreApiItem<ItemType> = {
    ...lastState,
    isLoading: false,
    errors,
    data: null,
    isSuccess: false,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const requestDeleteItem: <StateType, ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType
>(
  lastState: StateType,
  pathToUpdate: string[]
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: true,
    isSuccess: false,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-
/* istanbul ignore next */
export const receiveDeleteItem: <StateType, ItemType>(lastState: StateType, pathToUpdate: string[]) => StateType = <
  StateType,
  ItemType
>(
  lastState: StateType,
  pathToUpdate: string[]
) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    data: null,
    isSuccess: true,
  };

  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* istanbul ignore next */
export const errorDeleteItem: <StateType, ItemType>(
  lastState: StateType,
  pathToUpdate: string[],
  error: HttpErrorResponse
) => StateType = <StateType, ItemType>(lastState: StateType, pathToUpdate: string[], errors: HttpErrorResponse) => {
  const updatedPathState: Partial<IStoreApiItem<ItemType>> = {
    ...lastState,
    isLoading: false,
    errors,
    isSuccess: false,
  };

  /* istanbul ignore next */
  return getStateWithUpdatedPart(lastState, pathToUpdate, updatedPathState);
};
