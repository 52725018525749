import { IPermissionResponse, IRolePermission } from './../../state/role-management/models/role.model';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { AppUserPermissionList } from '../../models/app-types';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { UserAvatarComponent } from 'src/app/common/components/user-avatar/user-avatar.component';

@Component({
  selector: 'draeger-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
})
export class NavbarComponent implements OnInit {
  @Input() permissions: IPermissionResponse;
  userInitials: string;
  content!: any;

  constructor(
    private router: Router,
    public permissionService: PermissionsService,
    private translate: TranslateService,
    private _oktaAuthStateService: OktaAuthStateService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  ngOnInit(): void {
    this.content = UserAvatarComponent;

    this._oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated)
    ).subscribe((authState: AuthState) => {
      this.userInitials = authState.idToken?.claims.firstname[0] + ' ' + authState.idToken?.claims.lastname[0]
    });
  }

  navigateToDashboard(): void {
    const hasStatisticsAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasStatisticsAccess);
    const hasDrugCheckAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasDrugCheckAccess);

    if (hasStatisticsAccess) {
      this.router.navigate(['statistics']);
    } else if (!hasStatisticsAccess && hasDrugCheckAccess) {
      this.router.navigate(['test-data-drugcheck']);
    }
  }

  handlePrivacyPolicyClick(): void {
    window.open('https://product-assets.draeger.net/documents/impairment/draegeradd/PrivacyPolicy.pdf', '_blank');
  }

  handleTermsAndConditionsClick(): void {
    window.open('https://product-assets.draeger.net/documents/impairment/draegeradd/CloudServiceTermsofUse.pdf', '_blank')
  }

  selectLanguage(language: string) {
    localStorage.setItem('localizationLanguage', language);
    this.translate.use(language);
  }
}
