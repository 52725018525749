import { Component, Input, Output, EventEmitter, ContentChild, TemplateRef, inject } from '@angular/core';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { take } from 'rxjs';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { TestDataAlcotestActions } from 'src/app/test-data-alcotest/state/actions/test-data-alcotest.actions';

@Component({
  selector: 'draeger-details-section',
  templateUrl: './details-section.component.html',
  styleUrls: ['./details-section.component.sass']
})
export class DetailsSectionComponent extends OnDestroyMixin() {
  @Input() data: any;
  @Output() handleDeleteInfo: EventEmitter<any> = new EventEmitter();

  @ContentChild('confirmDeleteModalTemplate', { read: TemplateRef })
  public confirmDeleteTemplate: TemplateRef<any>;
  confirmDeleteModalReference: ModalRef<any>;
  private readonly modalService = inject(ModalService);

  isDeletePopupShown: boolean = false;

  alcotestIcons = [
    { value: 'mouthpiece_tube', path: "./assets/img/alcotest_tube.svg", alt: "Alcotest mouthpiece tube icon", tooltip: 'Mouthpiece tube' },
    { value: 'mouthpiece_funnel', path: "./assets/img/alcotest_funnel.svg", alt: "Alcotest mouthpiece funnel icon", tooltip: 'Mouthpiece funnel' },
    { value: 'trigger_passive', path: "./assets/img/alcotest_passive.svg", alt: "Alcotest trigger passive icon", tooltip: 'Passive' },
    { value: 'trigger_flow', path: "", alt: "Alcotest trigger flow icon" },
    { value: 'trigger_manual', path: "./assets/img/alcotest_manual.svg", alt: "Alcotest trigger manual icon", tooltip: 'Manual' },
    { value: 'trigger_not_applied', path: "", alt: "Alcotest trigger not applied icon" },
  ]

  constructor(
    private testDataAlcotestActions: TestDataAlcotestActions) {
    super()
  }

  onDeleteClick(event, link) {
    event.stopPropagation();
    this.isDeletePopupShown = true;

    this.confirmDeleteModalReference = this.modalService.open(this.confirmDeleteTemplate, {
      size: 'small',
      variant: 'default',
      dismissable: false,
    })

    this.confirmDeleteModalReference.onClose$.subscribe(() => {
      this.isDeletePopupShown = false;
    })

    this.confirmDeleteModalReference.onClose$.pipe(take(1)).subscribe((isDeleteConfirmed: boolean) => {
      if (isDeleteConfirmed) {
        this.deleteSectionInfo(link);
        this.isDeletePopupShown = false;
      }
    })
  }

  deleteSectionInfo(sectionDeleteLink: any) {
    this.testDataAlcotestActions.requestSectionDelete(sectionDeleteLink);
    this.handleDeleteInfo.emit(true);
  }

}
