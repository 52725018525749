const mapColors = {
  "grey-0": "#FFFFFF",
  "grey-1": "#DAE0E7",
  "grey-2": "#95a9bd",
  "grey-3": "#586B84",
  "grey-4": "#465A71",
  "grey-5": "#3A4C61",
  "grey-6": "#2D3C4C",
};

export const style = {
  styles: [
    {
      elementType: "geometry",
      stylers: [{ color: mapColors["grey-5"] }],
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-2"] }],
    },
    {
      elementType: "labels.text.stroke",
      stylers: [{ color: mapColors["grey-5"] }],
    },
    {
      featureType: "administrative",
      elementType: "geometry",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "administrative.country",
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-0"] }],
    },
    {
      featureType: "administrative.locality",
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-1"] }],
    },
    {
      featureType: "poi",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [{ color: "transparent" }],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [{ color: "transparent" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [{ color: mapColors["grey-3"] }],
    },
    {
      featureType: "road",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-2"] }],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [{ color: mapColors["grey-3"] }],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [{ color: mapColors["grey-3"] }],
    },
    {
      featureType: "road.highway.controlled_access",
      elementType: "geometry",
      stylers: [{ color: mapColors["grey-3"] }],
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-2"] }],
    },
    {
      featureType: "transit",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.text.fill",
      stylers: [{ color: mapColors["grey-2"] }],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [{ color: mapColors["grey-6"] }],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [{ visibility: "off" }],
    },
  ],
};
