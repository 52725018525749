import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[draegerInputDebounce]',
})
export class InputDebounceDirective implements OnInit, OnDestroy {

  @Input() delay: number = 500;
  @Output() debounce: EventEmitter<any> = new EventEmitter<any>();

  private inputDebouncer$: Subject<any> = new Subject();
  private subscriptions: Subscription[] = [];

  constructor(private readonly model: NgModel) { }

  ngOnInit(): void {
    this.setupInputDebouncer();
    this.setupEventStream();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: any) => subscription.unsubscribe());
  }

  /* istanbul ignore next */
  private setupInputDebouncer(): void {
    this.subscriptions.push(
      this.inputDebouncer$
        .pipe(
          debounceTime(this.delay),
          distinctUntilChanged()
        )
        .subscribe((value: any) => {
          this.debounce.emit(value);
        })
    );
  }

  /* istanbul ignore next */
  private setupEventStream(): void {
    this.subscriptions.push(
      this.model.valueChanges.subscribe(
        (value: any) => {
          if (this.isValueValid(value)) {
            this.inputDebouncer$.next(value.toString().trim());
          } else {
            this.inputDebouncer$.next('');
          }
        }
      )
    );
  }

  /* istanbul ignore next */
  private isValueValid(value: any): boolean {
    return value !== null && value !== undefined;
  }

}
