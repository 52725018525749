import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITestDataDrugCheckDetails } from 'src/app/test-data-drugcheck/models/test-data-drugcheck.model';
import { TestDataDrugCheckActionTypes } from '../test-data-drugcheck-action-types';

export class RequestTestDataDrugCheckDetails implements Action {
  readonly type: TestDataDrugCheckActionTypes.REQUEST_TEST_DATA_DRUGCHECK_DETAILS = TestDataDrugCheckActionTypes.REQUEST_TEST_DATA_DRUGCHECK_DETAILS;

  constructor(public payload?: any) { }
}
export class ReceiveTestDataDrugCheckDetails implements Action {
  readonly type: TestDataDrugCheckActionTypes.RECEIVE_TEST_DATA_DRUGCHECK_DETAILS = TestDataDrugCheckActionTypes.RECEIVE_TEST_DATA_DRUGCHECK_DETAILS;

  constructor(public payload: ITestDataDrugCheckDetails) { }
}
export class ErrorReceiveTestDataDrugCheckDetails implements Action {
  readonly type: TestDataDrugCheckActionTypes.ERROR_RECEIVE_TEST_DATA_DRUGCHECK_DETAILS = TestDataDrugCheckActionTypes.ERROR_RECEIVE_TEST_DATA_DRUGCHECK_DETAILS;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestSectionDelete implements Action {
  readonly type: TestDataDrugCheckActionTypes.REQUEST_SECTION_DELETE = TestDataDrugCheckActionTypes.REQUEST_SECTION_DELETE;

  constructor(public payload: any) { }
}
export class ReceiveSectionDelete implements Action {
  readonly type: TestDataDrugCheckActionTypes.RECEIVE_SECTION_DELETE = TestDataDrugCheckActionTypes.RECEIVE_SECTION_DELETE;

  constructor(public payload: any) { }
}
export class ErrorReceiveSectionDelete implements Action {
  readonly type: TestDataDrugCheckActionTypes.ERROR_RECEIVE_SECTION_DELETE = TestDataDrugCheckActionTypes.ERROR_RECEIVE_SECTION_DELETE;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUpdateLabResult implements Action {
  readonly type: TestDataDrugCheckActionTypes.REQUEST_UPDATE_LAB_RESULT = TestDataDrugCheckActionTypes.REQUEST_UPDATE_LAB_RESULT;

  constructor(public payload: any) { }
}
export class ReceiveUpdateLabResult implements Action {
  readonly type: TestDataDrugCheckActionTypes.RECEIVE_UPDATE_LAB_RESULT = TestDataDrugCheckActionTypes.RECEIVE_UPDATE_LAB_RESULT;

  constructor(public payload: any) { }
}
export class ErrorUpdateLabResult implements Action {
  readonly type: TestDataDrugCheckActionTypes.ERROR_UPDATE_LAB_RESULT = TestDataDrugCheckActionTypes.ERROR_UPDATE_LAB_RESULT;

  constructor(public payload: HttpErrorResponse) { }
}
