import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { ITestDataAlcotestPage } from 'src/app/test-data-alcotest/models/test-data-alcotest.model';
import { TestDataAlcotestActionTypes } from '../test-data-alcotest-action-types';

export class RequestTestDataAlcotestPage implements Action {
  readonly type: TestDataAlcotestActionTypes.REQUEST_TEST_DATA_ALCOTEST_PAGE = TestDataAlcotestActionTypes.REQUEST_TEST_DATA_ALCOTEST_PAGE;

  constructor(public payload?: any) { }
}
export class ReceiveTestDataAlcotestPage implements Action {
  readonly type: TestDataAlcotestActionTypes.RECEIVE_TEST_DATA_ALCOTEST_PAGE = TestDataAlcotestActionTypes.RECEIVE_TEST_DATA_ALCOTEST_PAGE;

  constructor(public payload: ITestDataAlcotestPage) { }
}
export class ErrorReceiveTestDataAlcotestPage implements Action {
  readonly type: TestDataAlcotestActionTypes.ERROR_RECEIVE_TEST_DATA_ALCOTEST_PAGE = TestDataAlcotestActionTypes.ERROR_RECEIVE_TEST_DATA_ALCOTEST_PAGE;

  constructor(public payload: HttpErrorResponse) { }
}

export class RequestUpdateAlcotestColumns implements Action {
  readonly type: TestDataAlcotestActionTypes.REQUEST_UPDATE_ALCOTEST_COLUMNS = TestDataAlcotestActionTypes.REQUEST_UPDATE_ALCOTEST_COLUMNS;

  constructor(public payload: any) { }
}
export class ReceiveUpdateAlcotestColumns implements Action {
  readonly type: TestDataAlcotestActionTypes.RECEIVE_UPDATE_ALCOTEST_COLUMNS = TestDataAlcotestActionTypes.RECEIVE_UPDATE_ALCOTEST_COLUMNS;

  constructor(public payload: any) { }
}
export class ErrorReceiveUpdateAlcotestColumns implements Action {
  readonly type: TestDataAlcotestActionTypes.ERROR_RECEIVE_UPDATE_ALCOTEST_COLUMNS = TestDataAlcotestActionTypes.ERROR_RECEIVE_UPDATE_ALCOTEST_COLUMNS;

  constructor(public payload: HttpErrorResponse) { }
}
