import { ReceivePermissions, ErrorReceivePermissions } from './../actions/role-action-types-creators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { RoleActionTypes } from '../actions/role-action-types';
import { RoleService } from '../../../services/role/role.service';

@Injectable()
export class RoleEffects {
  constructor(private action$: Actions, private roleService: RoleService) { }

  requestPermissions$: Observable<Action> = createEffect(() => {
    return this.action$.pipe(
      ofType(RoleActionTypes.REQUEST_PERMISSIONS),
      switchMap(() =>
        this.roleService.getPermissionList().pipe(
          map((permissions: any) => new ReceivePermissions(permissions)),
          catchError((error: HttpErrorResponse) => of(new ErrorReceivePermissions(error)))
        )
      )
    );
  });
}
