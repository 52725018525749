export const mapOptions: google.maps.MapOptions = {
  center: { lat: 53.8578205, lng: 10.6905946 },
  controlSize: 32,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  disableDoubleClickZoom: true,
  minZoom: 2,
};
