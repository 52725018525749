<div [ngClass]="{ 'hide-element': isLoading }">
  <draeger-container
    *ngIf="userLoggedIn; else loginDisplay"
    [permissions]="permissions"
    [userLoggedIn]="userLoggedIn"
    [userUnauthorized]="userUnauthorized"
  ></draeger-container>

  <ng-template #loginDisplay>
    <draeger-login></draeger-login>
  </ng-template>
  <div class="tabs-container" odxLayout="12">
    <div class="rail-nav-container">
  <draeger-tabs 
    *ngIf="
      !router.url?.includes('details') &&
      !router.url?.includes('calibration-accuracy') && userLoggedIn && !userUnauthorized
    "
    [permissions]="permissions"
  ></draeger-tabs>
  </div>
  <div class="router-container">
    <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div *ngIf="isLoading" class="homepage-loader">
  <div class="spinner-border spinner" role="status"></div>
</div>

<odx-toast-container [maxItems]="5"></odx-toast-container>