import { FiltersOptionsState, IFiltersOptionsState } from './filters-options.model';
import { AlcotestState, IAlcotestState } from './alcotest.model';
import { DrugCheckState, IDrugCheckState } from './drugcheck.model';
import { TestsOnMapState, ITestsOnMapState } from './tests-on-map.model';
import { ITestsPerCompanyState, TestsPerCompanyState } from './tests-per-company.model';

export interface IStatisticsState {
  filtersOptions: IFiltersOptionsState;
  alcotestStatistics: IAlcotestState;
  drugCheckStatistics: IDrugCheckState;
  testsOnMapStatistics: ITestsOnMapState;
  testsPerCompanyStatistics: ITestsPerCompanyState;
}

export class StatisticsState implements IStatisticsState {
  filtersOptions: IFiltersOptionsState = new FiltersOptionsState();
  alcotestStatistics: IAlcotestState = new AlcotestState();
  drugCheckStatistics: IDrugCheckState = new DrugCheckState();
  testsOnMapStatistics: ITestsOnMapState = new TestsOnMapState();
  testsPerCompanyStatistics: ITestsPerCompanyState = new TestsPerCompanyState();
}
