export enum AppUserPermissionList {
  hasAppAccess = 'has_app_access',
  hasWebAccess = 'has_web_access',
  hasUsersAccess = 'has_users_access',
  hasCompaniesAccess = 'has_companies_access',
  hasDevicesAccess = 'has_devices_access',
  hasStatisticsAccess = 'has_statistics_access',
  hasMeasurementsAccess = 'has_measurements_access',
  hasAlcotestAccess = 'has_alcotest_access',
  hasDrugCheckAccess = 'has_drugcheck_access',
  canViewTestsPerCompany='can_view_tests_per_company'
}
