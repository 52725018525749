<!-- User management action bar -->
<div
  class="odx-bar-parent"
  *ngIf="router.url?.includes('user-management') && selectedItem"
>
  <odx-bar>
    <button
      odxBarButton
      title="Edit"
      [disabled]="
        !selectedItem || router.url?.includes('update') || isDeletePopupShown
      "
      (click)="onEditClick($event)"
      type="button"
    >
      <odx-icon size="large" name="edit"></odx-icon>
    </button>
    <button
      odxBarButton
      title="Delete"
      [disabled]="
        !selectedItem || router.url?.includes('update') || isDeletePopupShown
      "
      (click)="onDeleteClick($event)"
      type="button"
    >
      <odx-icon size="large" name="delete"></odx-icon>
    </button>
  </odx-bar>
</div>

<!-- Companies action bar -->
<div
  class="odx-bar-parent"
  *ngIf="router.url?.includes('companies') && selectedItem"
>
  <odx-bar>
    <button
      odxBarButton
      title="Edit"
      [disabled]="
        !selectedItem || router.url?.includes('update') || isDeletePopupShown
      "
      (click)="onEditClick($event)"
      type="button"
    >
      <odx-icon size="large" name="edit"></odx-icon>
    </button>
    <button
      odxBarButton
      title="Delete"
      [disabled]="
        !selectedItem || router.url?.includes('update') || isDeletePopupShown
      "
      (click)="onDeleteClick($event)"
      type="button"
    >
      <odx-icon size="large" name="delete"></odx-icon>
    </button>
  </odx-bar>
</div>

<!-- Test Data Drugcheck action bar -->
<div
  class="odx-bar-parent"
  *ngIf="router.url?.includes('test-data-drugcheck') && selectedItem"
>
  <odx-bar>
    <button
      odxBarButton
      title="View details"
      (click)="onViewDetailsClick($event)"
      type="button"
    >
      <odx-icon size="large" name="view"></odx-icon>
    </button>
  </odx-bar>
</div>

<!-- Test Data Alcotest action bar -->
<div
  class="odx-bar-parent"
  *ngIf="
    router.url?.includes('test-data-alcotest') &&
    selectedItem &&
    (selectedItem?.has_workflow_data || selectedItem?.completed_workflow_id)
  "
>
  <odx-bar>
    <button
      odxBarButton
      title="View details"
      (click)="onViewDetailsClick($event)"
      type="button"
    >
      <odx-icon size="large" name="view"></odx-icon>
    </button>
  </odx-bar>
</div>

<!-- Active devices action bar -->
<div
  class="odx-bar-parent"
  *ngIf="router.url?.includes('active-devices') && selectedItem"
>
  <odx-bar>
    <button
      odxBarButton
      title="View details"
      (click)="onViewDetailsClick($event)"
      type="button"
    >
      <odx-icon size="large" name="view"></odx-icon>
    </button>
  </odx-bar>
</div>
