import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITestDataAlcotestState } from './../models/test-data-alcotest.model';
import { RequestSectionDelete, RequestTestDataAlcotestDetails } from './test-data-alcotest-action-types-creators/test-data-alcotest-details-action-types-creators';
import { RequestTestDataAlcotestPage, RequestUpdateAlcotestColumns } from './test-data-alcotest-action-types-creators/test-data-alcotest-page-action-types-creators';

@Injectable({ providedIn: 'root' })
export class TestDataAlcotestActions {
  constructor(private store: Store<ITestDataAlcotestState>) { }

  public requestTestDataAlcotestPage(params?: any): void {
    this.store.dispatch(new RequestTestDataAlcotestPage(params));
  }

  public requestTestDataAlcotestDetails(params?: any): void {
    this.store.dispatch(new RequestTestDataAlcotestDetails(params))
  }

  public requestSectionDelete(params: any): void {
    this.store.dispatch(new RequestSectionDelete(params));
  }

  public requestUpdateAlcotestColumns(params: any): void {
    this.store.dispatch(new RequestUpdateAlcotestColumns(params));
  }

}
