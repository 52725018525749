import { DatePipe } from '@angular/common';
import * as moment from 'moment-timezone';

export const getDateAsNgbStruct: (date: Date) => any = (date: Date) => {
  if (!date) {
    return null;
  }

  return { day: date.getDate(), month: date.getMonth() + 1, year: date.getFullYear() };
};

export const formatLocaleTime: (date: Date) => string = (date: Date) => {
  if (!date) { return; }

  return moment.tz(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone).format('HH:mm');
};

export const formatLocaleDate: (date: Date) => string = (date: Date) => {
  if (!date) { return; }

  return moment.utc(date).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD/MM/YYYY - HH:mm');
};

export const dateFormat: (date: string, formatDate: string) => string = (date: string, formatDate: string) => {
  const dateString: string = new DatePipe('en-GB').transform(date, formatDate);
  const timeString: string = moment
    .tz(new Date(date), Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format('HH:mm');

  return `${dateString}, ${timeString}`;
};

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const formatDateToISO8601: (date: Date) => string = (date: Date) => {
  if (!date) { return; }
  return moment.utc(date).toISOString();
}
