import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { Component, OnInit, EventEmitter, Input, Output, Injector } from '@angular/core';
import { getDateAsNgbStruct } from 'src/app/common/utils/date-utils/date.utils';
import { TestDataDrugCheckActions } from 'src/app/test-data-drugcheck/state/actions/test-data-drugcheck.actions';

@Component({
  selector: 'draeger-measurement-results',
  templateUrl: './measurement-results.component.html',
  styleUrls: ['./measurement-results.component.sass']
})
export class MeasurementResultsComponent extends OnDestroyMixin() implements OnInit {

  @Input() data: any;
  @Output() handleEditLabResults: EventEmitter<any> = new EventEmitter();

  testDataDrugCheckACtions: TestDataDrugCheckActions;
  screeningResults: any[] = [];
  labResults: any[] = [];
  isVisibleLabResults: boolean = false;
  showTooltip: boolean;
  selectedLabAnalysisDate: Date;
  errorMessages: any[] = [];
  statusValues: any[] = [{ id: 2, value: '-' }, { id: 1, value: 'detected' }, { id: 0, value: 'not_detected' }];
  screeningResultsFilterStatus: string = 'not_tested';
  defaultLabAnalysis: any = {
    analysis_date: '',
    thc: 2,
    concentration_thc: '0',
    opi: 2,
    concentration_opi: '0',
    met: 2,
    concentration_met: '0',
    amp: 2,
    concentration_amp: '0',
    coc: 2,
    concentration_coc: '0',
    bzo: 2,
    concentration_bzo: '0',
    oxy: 2,
    concentration_oxy: '0',
  };

  defaultLabAnalysisEdit: any = {
    lab_thc: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_opi: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_met: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_amp: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_coc: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_bzo: {
      status: '-',
      concentration: '0 ng/ml'
    },
    lab_oxy: {
      status: '-',
      concentration: '0 ng/ml'
    }
  };

  constructor(private injector: Injector) {
    super()
    this.testDataDrugCheckACtions = this.injector.get<TestDataDrugCheckActions>(TestDataDrugCheckActions)
  }

  ngOnInit(): void {
    this.setScreeningResultsArray();
    this.setLabResultsArray();
    this.onDateChange(new Date(Date.now()));
  }


  /* istanbul ignore next */
  setScreeningResultsArray() {
    const rawScreeningResults: any = this.data?.screening_results ? this.data?.screening_results : {};
    const array = [];

    Object.keys(rawScreeningResults).forEach((key: string) => {
      array.push({ name: key });
    });

    Object.values(rawScreeningResults).forEach((elem: any, i) => {
      if (typeof elem === 'object') {
        array.forEach((el, j) => {
          if (i === j) {
            this.screeningResults.push({ ...el, ...elem });
          }
        });
      }
    });

    this.screeningResults = this.screeningResults.filter((result) => result.status !== this.screeningResultsFilterStatus)
  }

  setLabResultsArray() {
    const rawLabResults: any = this.data?.lab_results ? this.data?.lab_results : this.defaultLabAnalysisEdit;
    const array = [];

    Object.keys(rawLabResults).forEach((key: string) => {
      array.push({ name: key });
    });

    Object.values(rawLabResults).forEach((elem: any, i) => {
      if (typeof elem === 'object') {
        array.forEach((el, j) => {
          if (i === j) {
            this.labResults.push({ ...el, ...elem });
          }
        });
      }
    });

    // Filter the labResults array based on the screeningResults
    var screeningResultsKeys = this.screeningResults.map(obj => obj.name.split('_')[1]);

    this.labResults = this.labResults.filter(obj => {
      return screeningResultsKeys.includes(obj.name.split('_')[1]);
    });

    this.labResults.forEach(labResult => {
      this.defaultLabAnalysis['concentration_' + labResult.name.split('_')[1]] = labResult?.concentration?.split(' ')[0]
    })
  }

  onEditLabResults() {
    this.isVisibleLabResults = true;
  }

  onSaveLabResults() {
    this.isVisibleLabResults = false;

    this.handleEditLabResults.emit({ ...this.defaultLabAnalysis });
  }

  onDateChange(date: Date) {
    const splitOperator: string = '.';
    const dateAsNgbStruct: any = getDateAsNgbStruct(new Date(date));

    this.defaultLabAnalysis.analysis_date = `${dateAsNgbStruct.day}${splitOperator}${dateAsNgbStruct.month}${splitOperator}${dateAsNgbStruct.year}`;

  }

  /* istanbul ignore next */
  onInputChange(value: any, field: string): void {
    const index = this.errorMessages?.findIndex((obj => obj?.field == field));

    if (value === '') {
      value = '0';
    }

    this.defaultLabAnalysis[field] = parseFloat(value).toFixed(3);

    if (this.defaultLabAnalysis[field]?.split('.')[0].length > 5) {

      if (index !== -1) {
        this.errorMessages[index] = { field, message: 'before' };
      }

      if (index === -1) {
        this.errorMessages.push({ field, message: 'before' });
      }
    } else {
      if (index !== -1) {
        this.errorMessages.splice(index, 1);
      }
    }
  }

  onDropdownChange(value: any, field: string): void {
    const selectedStatusValue = this.statusValues.find(element => element.value === value);

    this.defaultLabAnalysis[field] = selectedStatusValue?.id;
  }
}
