import { ContainerComponent } from './../components/container/container.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserManagementModuleGuard } from 'src/app/user-management/modules/user-management.module.guard';
import { CompaniesModuleGuard } from 'src/app/companies/modules/companies.module.guard';
import { StatisticsModuleGuard } from 'src/app/statistics/modules/statistics.module.guard';
import { TestDataAlcotestModuleGuard } from 'src/app/test-data-alcotest/modules/test-data-alcotest.module.guard';
import { TestDataDrugCheckModuleGuard } from 'src/app/test-data-drugcheck/modules/test-data-drugcheck.module.guard';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { onAuthRequired, onAuthResume } from 'src/app/common/utils/auth.config';
import { ActiveDevicesModuleGuard } from 'src/app/active-devices/modules/active-devices.module.guard';

export const routes: Routes = [
  {
    path: 'statistics',
    canActivate: [OktaAuthGuard, StatisticsModuleGuard],
    loadChildren: () =>
      import('../../statistics/modules/statistics.module').then(
        (m: typeof import('../../statistics/modules/statistics.module')) => m.StatisticsModule
      ),
    title: "Draeger | Statistics",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: 'test-data-alcotest',
    canActivate: [OktaAuthGuard, TestDataAlcotestModuleGuard],
    loadChildren: () =>
      import('../../test-data-alcotest/modules/test-data-alcotest.module').then(
        (m: typeof import('../../test-data-alcotest/modules/test-data-alcotest.module')) => m.TestDataAlcotestModule
      ),
    title: "Draeger | Test Data - Alcotest",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: 'test-data-drugcheck',
    canActivate: [OktaAuthGuard, TestDataDrugCheckModuleGuard],
    loadChildren: () =>
      import('../../test-data-drugcheck/modules/test-data-drugcheck.module').then(
        (m: typeof import('../../test-data-drugcheck/modules/test-data-drugcheck.module')) => m.TestDataDrugCheckModule
      ),
    title: "Draeger | Test Data - Drugcheck",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: 'active-devices',
    canActivate: [OktaAuthGuard, ActiveDevicesModuleGuard],
    loadChildren: () =>
      import('../../active-devices/modules/active-devices.module').then(
        (m: typeof import('../../active-devices/modules/active-devices.module')) => m.ActiveDevicesModule
      ),
    title: "Draeger | Active Devices",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: 'user-management',
    canActivate: [OktaAuthGuard, UserManagementModuleGuard],
    loadChildren: () =>
      import('../../user-management/modules/user-management.module').then(
        (m: typeof import('../../user-management/modules/user-management.module')) => m.UserManagementModule
      ),
    title: "Draeger | User Management",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: 'companies',
    canActivate: [OktaAuthGuard, CompaniesModuleGuard],
    loadChildren: () =>
      import('../../companies/modules/companies.module').then(
        (m: typeof import('../../companies/modules/companies.module')) => m.CompaniesModule
      ),
    title: "Draeger | Companies",
    data: {
      onAuthRequired: onAuthRequired,
      onAUthResume: onAuthResume
    }
  },
  {
    path: '',
    component: ContainerComponent,
    pathMatch: 'full'
  },
  {
    path: 'okta/callback',
    component: OktaCallbackComponent
  },
  { path: '**', redirectTo: '' },
];

const isIframe: boolean = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
