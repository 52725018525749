import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { IStoreApiList, StoreApiList } from './../../../common/models/store-api-list.model';
import { IUserPage } from './../../models/user.model';
import { ICompaniesList } from './../../models/companies.model';
import { IUser, IUserRole } from '../../models/user.model';

export interface IUserState {
  usersPage: IStoreApiItem<IUserPage>;
  user: IStoreApiItem<IUser>;
  roleList: IStoreApiList<IUserRole[]>;
  companiesList: IStoreApiItem<ICompaniesList>;
}

export class UserState implements IUserState {
  usersPage: IStoreApiItem<IUserPage> = new StoreApiItem();
  user: IStoreApiItem<IUser> = new StoreApiItem();
  roleList: IStoreApiList<IUserRole[]> = new StoreApiList();
  companiesList: IStoreApiItem<ICompaniesList> = new StoreApiItem();
}

