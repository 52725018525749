import { NotificationState } from './../models/notification.model';
import { GenericAction } from '../../models/generic-action.model';
import { NotificationActionTypes } from '../actions/notification-action-types';
import { INotificationState } from '../models/notification.model';

const requestShowNotification = (
  lastState: INotificationState,
  action: GenericAction<NotificationActionTypes, INotificationState>
): INotificationState => ({
  ...lastState,
  type: action.payload?.type,
  message: action.payload?.message,
  value: action.payload?.value,
  category: action.payload?.category,
});

export function notificationReducer(
  lastState: INotificationState = new NotificationState(),
  action: GenericAction<NotificationActionTypes, any>
): INotificationState {
  switch (action.type) {
    case NotificationActionTypes.REQUEST_SHOW_NOTIFICATION:
      return requestShowNotification(lastState, action);

    default:
      return lastState;
  }
}
