import { Injector } from "@angular/core";
import { Router } from "@angular/router";
import OktaAuth from "@okta/okta-auth-js";
import { environment } from "src/environments/environment";

export function onAuthRequired(oktaAuth: OktaAuth, injector: Injector): void {
  const router = injector.get(Router);
  router.navigate(['']);
}

export function onAuthResume(oktaAuth: OktaAuth, injector: Injector): void {
  const router = injector.get(Router);
  router.navigate(['statistics']);
}

const config = {
  issuer: environment.OKTA.ISSUER,
  clientId: environment.OKTA.CLIENT_ID,
  redirectUri: `${window.document.baseURI}okta/callback`,
  postLogoutRedirectUri: window.document.baseURI,
  onAuthRequired: onAuthRequired,
  onAuthResume: onAuthResume,
};

export const oktaAuth = new OktaAuth(config);