import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ICompaniesPage, ICompanyOptions } from './../../models/companies.model';
import { ICompany } from '../../models/companies.model';

export interface ICompanyState {
  companiesPage: IStoreApiItem<ICompaniesPage>;
  companies: IStoreApiItem<ICompany>;
  companyOptions: IStoreApiItem<ICompanyOptions>;
  addEditCompany: IStoreApiItem<ICompany>;
}

export class CompanyState implements ICompanyState {
  companiesPage: IStoreApiItem<ICompaniesPage> = new StoreApiItem();
  companies: IStoreApiItem<ICompany> = new StoreApiItem();
  companyOptions: IStoreApiItem<ICompanyOptions> = new StoreApiItem();
  addEditCompany: IStoreApiItem<ICompany> = new StoreApiItem();
}

