import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, inject } from '@angular/core';
import { Router } from '@angular/router';
import { ModalRef, ModalService } from '@odx/angular/components/modal';
import { take } from 'rxjs/operators';

@Component({
  selector: 'draeger-crud-footer-items',
  templateUrl: './crud-footer-items.component.html',
  styleUrls: ['./crud-footer-items.component.sass'],
})
export class CrudFooterItemsComponent<T> implements OnInit {
  @Input() isLoading: boolean;
  @Input() selectedItem: T;
  @Input() form: any;
  @Output() handleEdit: EventEmitter<void> = new EventEmitter();
  @Output() handleDelete: EventEmitter<boolean> = new EventEmitter();
  @Output() handleViewDetails: EventEmitter<void> = new EventEmitter();

  @ContentChild('confirmDeleteModalTemplate', { read: TemplateRef })
  public confirmDeleteModalTemplate: TemplateRef<any>;
  confirmDeleteModalReference: ModalRef<any>;
  private readonly modalService = inject(ModalService);

  isDeletePopupShown: boolean = false;

  constructor(
    public router: Router,
  ) { }

  ngOnInit(): void {

  }

  onEditClick(e: Event): void {
    e.stopPropagation();
    this.handleEdit.emit();
  }

  onViewDetailsClick(e: Event): void {
    e.stopPropagation();
    this.handleViewDetails.emit();
  }

  /* istanbul ignore next */
  onDeleteClick(e: Event): void {
    e.stopPropagation();
    this.isDeletePopupShown = true;

    this.confirmDeleteModalReference = this.modalService.open(this.confirmDeleteModalTemplate, {
      size: 'small',
      variant: 'default',
      dismissable: false,
    });

    this.confirmDeleteModalReference.onClose$.subscribe(() => {
      this.isDeletePopupShown = false;
    });

    this.confirmDeleteModalReference.onClose$.pipe(take(1)).subscribe((isDeleteConfirmed: boolean) => {
      if (isDeleteConfirmed) {
        this.handleDelete.emit(isDeleteConfirmed);
        this.isDeletePopupShown = false;
      }
    });
  }

}
