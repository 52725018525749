import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { CommonConstants, NotificationActions } from 'src/app/common';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private zone: NgZone, private injector: Injector) { }

  handleError(error: any): void {
    const notificationActions: NotificationActions = this.injector.get<NotificationActions>(NotificationActions);

    this.zone.run(() =>
      notificationActions.requestShowNotification(
        CommonConstants.notificationType.ERROR,
        'An error occurred. Please try again later.'
      )
    );
    console.error('UI ERROR => Error from global error handler:', error);
  }
}
