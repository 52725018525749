import { AppUserPermissionList } from 'src/app/root/models/app-types';
import { Injectable } from '@angular/core';

import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { PermissionsService } from 'src/app/root';

@Injectable()
export class CompaniesModuleGuard extends OnDestroyMixin()  {

  constructor(
    private permissionService: PermissionsService
  ) {
    super();
    this.permissionService.readPermissionListFromStore(this.destroy, AppUserPermissionList.hasCompaniesAccess);
  }

  canActivate(): boolean {
    return this.permissionService.canActivateModule(AppUserPermissionList.hasCompaniesAccess);
  }
}
