import { AppComponent } from './components/app/app.component';
import { ContainerComponent } from './components/container/container.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppModule } from './modules/app.module';
import { ErrorHandlerModule } from './modules/error-handler.module';
import { LoginComponent } from './components/login/login.component';
import { RoleActions } from './state/role-management/actions/role.actions';
import { RoleService } from './services/role/role.service';
import { RoleEffects } from './state/role-management/effects/role.effects';
import { RoleState, IRoleState } from './state/role-management/models/role.model';
import { roleReducer } from './state/role-management/reducers/role.reducer';
import * as roleSelectors from './state/role-management/selectors/role.selector';
import { PermissionsService } from './services/permissions/permissions.service';
import { TabsComponent } from './components/tabs/tabs.component';
import { GlobalErrorHandler } from './services/errors/global-error-handler';
import { HttpErrorInterceptor } from './services/errors/http-error.interceptor';

export {
    AppModule,
    AppRoutingModule,
    ErrorHandlerModule,
    AppComponent,
    ContainerComponent,
    NavbarComponent,
    TabsComponent,
    LoginComponent,
    RoleActions,
    RoleService,
    PermissionsService,
    RoleEffects,
    RoleState,
    IRoleState,
    roleReducer,
    roleSelectors,
    GlobalErrorHandler,
    HttpErrorInterceptor,
};
