
import { IStoreApiList, StoreApiList } from '../../../../common/models/store-api-list.model';

export interface IRoleState {
  permissions: IStoreApiList<IPermissionResponse>;
}

export class RoleState implements IRoleState {
  permissions: IStoreApiList<IPermissionResponse> = new StoreApiList();
}

export interface IRole {
  id: string;
  name?: string;
  localizedName?: string;
}

export class Role implements IRole {
  id: string;
  name: string;

  constructor();
  constructor(role: IRole);
  constructor(role?: IRole) {
    if (!role) {
      this.name = null;
    } else {
      this.name = role.name
    }
  }
}

export interface IRolePermission {
  permission: string,
  enabled?: boolean,
  label?: string;
  localizedName?: string;
}

export class RolePermission implements IRolePermission {
  permission: string;
  enabled: boolean;
  label?: string;
  localizedName?: string;

  constructor();
  constructor(permission: IRolePermission);
  constructor(permission?: IRolePermission) {
    if (!permission) {
      this.permission = null;
      this.enabled = null;
    } else {
      this.enabled = permission.enabled;
      this.permission = permission.permission;
    }
  }
}

export interface IPermissionResponse {
  permissions: Array<IRolePermission>;
  id: number;
  has_alcotest_access: boolean;
  has_drugcheck_access: boolean;
  access_level: string;
}

export class PermissionResponse implements IPermissionResponse {
  permissions: Array<IRolePermission>;
  id: number;
  has_alcotest_access: boolean;
  has_drugcheck_access: boolean;
  access_level: string;
  constructor();
  constructor(permission: IPermissionResponse);
  constructor(permission?: IPermissionResponse) {
    if (!permission) {
      this.permissions = null;
      this.id = null;
      this.has_alcotest_access = null;
      this.has_drugcheck_access = null;
      this.access_level = null;
    } else {
      this.permissions = permission.permissions;
      this.id = permission.id;
      this.has_alcotest_access = permission.has_alcotest_access;
      this.has_drugcheck_access = permission.has_drugcheck_access;
      this.access_level = permission.access_level;
    }
  }
}