import { CommonModule as AngularCommonModule } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { TableService } from './../services/table/table.service';
import { SortIconComponent } from '../components/sort-icon/sort-icon.component';
import { TableComponent } from '../components/table/table.component';
import { WindowService } from '../services/window/window.service';
import { AppHttpInterceptor } from '../services/app-interceptor/app-interceptor';
import { InputDebounceDirective } from '../directives/input-debounce.directive';
import { CrudFooterItemsComponent } from './../components/crud-footer-items/crud-footer-items.component';
import { DatePickerComponent } from '../components/datepicker/datepicker.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapsCoordinatesComponent } from '../components/maps-coordinates/maps-coordinates.component';
import { MapService } from '../services/map/map.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './../utils/http-loader.factory';
import { TableV2Component } from '../components/table-v2/table-v2.component';
import { TooltipModule } from 'primeng/tooltip';
import { DetailsSectionComponent } from '../components/details-section/details-section.component';
import { MeasurementResultsComponent } from '../components/measurement-results/measurement-results.component';
import { ModalModule as ModalModuleOdx } from '@odx/angular/components/modal';
import { HeaderModule as OdxHeaderModule } from '@odx/angular/components/header';
import { DropdownModule as OdxDropdownModule } from "@odx/angular/components/dropdown"
import { IconComponent } from '@odx/angular/components/icon';
import { UserAvatarComponent } from '../components/user-avatar/user-avatar.component';
import { MainMenuModule } from '@odx/angular/components/main-menu';
import { MenuModule } from '@odx/angular/components/menu';
import { BarModule } from '@odx/angular/components/bar';

@NgModule({
    declarations: [
        SortIconComponent,
        TableComponent,
        DatePickerComponent,
        MapsCoordinatesComponent,
        InputDebounceDirective,
        CrudFooterItemsComponent,
        TableV2Component,
        DetailsSectionComponent,
        MeasurementResultsComponent,
    ],
    exports: [
        SortIconComponent,
        TableComponent,
        InputDebounceDirective,
        CrudFooterItemsComponent,
        DatePickerComponent,
        TranslateModule,
        TableV2Component,
        DetailsSectionComponent,
        MeasurementResultsComponent,
        AngularCommonModule,
        TooltipModule,
        ModalModuleOdx,
        OdxHeaderModule,
        OdxDropdownModule,
        IconComponent,
        UserAvatarComponent,
        MainMenuModule,
        MenuModule,
    ], imports: [AngularCommonModule,
        FormsModule,
        MultiSelectModule,
        DropdownModule,
        TableModule,
        CalendarModule,
        GoogleMapsModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        TooltipModule,
        ModalModuleOdx,
        OdxHeaderModule,
        OdxDropdownModule,
        IconComponent,
        UserAvatarComponent,
        MainMenuModule,
        MenuModule,
        BarModule], providers: [
            WindowService,
            TableService,
            MapService,
            { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
            provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
        ]
})
export class CommonModule { }
