<p-table
  #draegerTable
  styleClass="p-datatable-striped p-datatable-gridlines"
  class="draeger-table"
  [columns]="visibleColumns"
  [value]="dataTable"
  [scrollable]="true"
  scrollHeight="flex"
  [lazy]="lazy"
  [loading]="isLoading"
  [paginator]="paginator"
  [rows]="rows"
  [first]="first"
  [totalRecords]="totalRecords"
  [rowsPerPageOptions]="rowsPerPageOptions"
  (onSort)="changeSortIcon($event)"
  (clickOutside)="removeSelectedRow()"
  (onPage)="onPageOrRowChange($event)"
  (onLazyLoad)="onLazyLoadingData($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        *ngFor="let col of columns"
        scope="col"
        [ngStyle]="{ width: col.width ? col.width : '260px', flex: 'auto' }"
        [ngClass]="{
          'selected-sort-column': sortColumn?.key === col.key,
          'deselected-sort-column': sortColumn?.key !== col.key
        }"
      >
        <div class="sort-area" [pSortableColumn]="col.order">
          <div
            [ngStyle]="{
              width: col.width ? 'calc( ' + col.width + ' - 20px )' : '190px'
            }"
            style="
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            "
            pTooltip="{{ col.key | translate }}"
            tooltipPosition="bottom"
          >
            {{ col.key | translate }}
          </div>
          <draeger-sort-icon
            *ngIf="col.order"
            [columnName]="col.order"
            [columnSorted]="sortColumn"
          ></draeger-sort-icon>
        </div>
      </th>
    </tr>

    <tr *ngIf="displayFilters">
      <th
        *ngFor="let col of columns"
        [ngStyle]="{ width: col.width ? col.width : '260px', flex: 'auto' }"
        scope="col"
        [ngClass]="{
          'selected-sort-column': sortColumn?.key === col.key,
          'deselected-sort-column': sortColumn?.key !== col.key
        }"
      >
        <div
          scope="col"
          *ngFor="let customFilter of customFilterLayout"
          [ngClass]="{ 'w-100': col.filter === customFilter }"
        >
          <ng-container *ngIf="col.filter">
            <p-columnFilter
              *ngIf="
                col.filter === customFilter &&
                (col.type === 'TextAlphaNumeric' ||
                  col.type === 'MultiLineTextAlphaNumeric')
              "
              field="col.key"
              matchMode="contains"
              [showMenu]="false"
              [showClearButton]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  type="text"
                  class="p-inputtext p-component p-element"
                  pInputText
                  [delay]="600"
                  [ngModel]="
                    tableStoredFilters && tableStoredFilters[col.filter]
                  "
                  (keyup.enter)="
                    onTextFilterChange($event.target.value, col.filter)
                  "
                />
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              *ngIf="col.filter === customFilter && col.type === 'Range'"
              field="col.field"
              matchMode="contains"
              [showMenu]="false"
              [showClearButton]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  draegerInputDebounce
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  class="p-inputtext p-component p-element w-50"
                  pInputText
                  step="any"
                  [min]="0"
                  [delay]="600"
                  placeholder="Min"
                  [ngModel]="
                    tableStoredFilters && tableStoredFilters['test_result_min']
                  "
                  (keyup.enter)="
                    onTextFilterChange($event.target.value, 'test_result_min')
                  "
                />
                <input
                  draegerInputDebounce
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  class="p-inputtext p-component p-element w-50"
                  pInputText
                  step="any"
                  [min]="0"
                  [delay]="600"
                  placeholder="Max"
                  [ngModel]="
                    tableStoredFilters && tableStoredFilters['test_result_max']
                  "
                  (keyup.enter)="
                    onTextFilterChange($event.target.value, 'test_result_max')
                  "
                />
              </ng-template>
            </p-columnFilter>

            <p-columnFilter
              *ngIf="col.filter === customFilter && col.type === 'Number'"
              field="col.field"
              matchMode="contains"
              [showMenu]="false"
              [showClearButton]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <input
                  draegerInputDebounce
                  type="number"
                  onkeydown="return event.keyCode !== 69"
                  class="p-inputtext p-component p-element"
                  pInputText
                  step="any"
                  [min]="0"
                  [delay]="600"
                  [ngModel]="
                    tableStoredFilters && tableStoredFilters[col.filter]
                  "
                  (keyup.enter)="
                    onTextFilterChange($event.target.value, col.filter)
                  "
                />
              </ng-template>
            </p-columnFilter>

            <div
              *ngIf="
                col.filter === customFilter &&
                col.type === 'DatePicker' &&
                col.key !== 'DrugCheckExpiryDate' &&
                col.key !== 'LabExpiryDate'
              "
            >
              <draeger-datepicker
                [selectionMode]="datePickerSelectionMode"
                [formatDate]="datePickerFormat"
                [readOnly]="readOnlyDatePicker"
                [appendTo]="'body'"
                [showTime]="datePickerShowTime"
                [showSeconds]="datePickerShowSeconds"
                [defaultRange]="filtersList && filtersList[col.filter]"
                (dateSelect)="onDateFilterSelect($event, col.filter)"
                (dateChange)="onDateFilterChange(col.filter)"
              >
              </draeger-datepicker>
            </div>

            <div
              *ngIf="
                col.filter === customFilter &&
                col.type === 'DatePicker' &&
                (col.key === 'DrugCheckExpiryDate' ||
                  col.key === 'LabExpiryDate')
              "
            >
              <draeger-datepicker
                [selectionMode]="datePickerSelectionMode"
                [formatDate]="'mm/yy'"
                [readOnly]="readOnlyDatePicker"
                [appendTo]="'body'"
                [showTime]="datePickerShowTime"
                [showSeconds]="datePickerShowSeconds"
                [defaultRange]="filtersList && filtersList[col.filter]"
                (dateSelect)="onDateFilterSelect($event, col.filter)"
                (dateChange)="onDateFilterChange(col.filter)"
                [monthView]="true"
              >
              </draeger-datepicker>
            </div>

            <!-- temporarily disabled multi select  -->
            <span>
              <p-columnFilter
                *ngIf="
                  col.filter === customFilter && col.type === 'Multiselect'
                "
                field="col.key"
                matchMode="in"
                [showMenu]="false"
                [showClearButton]="false"
              >
                <ng-template
                  pTemplate="filter"
                  let-value
                  let-filter="filterCallback"
                >
                  <p-multiSelect
                    ngDefaultControl
                    class="report-center-table-ms"
                    [ngModel]="filtersList && filtersList[col.filter]"
                    [options]="col.options"
                    (onChange)="onMultiSelectChange($event.value, col.filter)"
                    (onClear)="onMultiSelectChange([], col.filter)"
                    optionLabel="value"
                    optionValue="key"
                    [placeholder]="'-'"
                    [showClear]="true"
                    [filter]="true"
                    [appendTo]="draegerTable"
                    [resetFilterOnHide]="true"
                  >
                    <ng-template let-option pTemplate="item">
                      <div class="p-multiselect-representative-option">
                        <span>{{ option.key | translate }}</span>
                      </div>
                    </ng-template>
                    <ng-template let-option pTemplate="selectedItem">
                      <span>{{ option.value | translate }}</span>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </span>

            <p-columnFilter
              *ngIf="col.filter === customFilter && col.type === 'Picklist'"
              field="col.key"
              matchMode="equals"
              [showMenu]="false"
              [showClearButton]="false"
            >
              <ng-template
                pTemplate="filter"
                let-value
                let-filter="filterCallback"
              >
                <p-dropdown
                  [ngModel]="filtersList && filtersList[col.filter]"
                  [appendTo]="draegerTable"
                  ngDefaultControl
                  [options]="col.options"
                  optionLabel="value"
                  optionValue="key"
                  (onChange)="onDropdownChange($event, col.filter, col.options)"
                  [showClear]="true"
                  [placeholder]="'-'"
                  [filter]="true"
                  [filterBy]="col.filter === 'screening_status' ? value : key"
                  [resetFilterOnHide]="true"
                >
                  <ng-template let-option pTemplate="item">
                    <span>{{
                      col.filter === "screening_status"
                        ? (option.value | translate)
                        : (option.key | translate)
                    }}</span>
                  </ng-template>
                  <ng-template let-option pTemplate="selectedItem">
                    <span>{{
                      col.filter === "screening_status"
                        ? (option.value | translate)
                        : (option.key | translate)
                    }}</span>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>

            <div
              *ngIf="col.filter === customFilter && col.type === 'Maps'"
            >
              <draeger-maps-coordinates
                (handleLocation)="onLocationChange($event, col.filter)"
                [selectedCoordinates]="selectedCoordinates"
              >
              </draeger-maps-coordinates>
            </div>
          </ng-container>
        </div>
      </th>
    </tr>

    <div
      class="table-header-right-wrapper"
      [ngClass]="{
        'selected-sort-column': sortColumn?.key === lastTableElement,
        'deselected-sort-column': sortColumn?.key !== lastTableElement
      }"
    ></div>
  </ng-template>
  <ng-template pTemplate="body" let-data let-columns="columns">
    <tr
      (click)="onSelect(data)"
      [ngClass]="{
        'selected-row': selectedData?.id === data.id
      }"
      [pSelectableRow]="data"
    >
      <td
        *ngFor="let col of columns"
        [ngStyle]="{
          width: col.width ? col.width : '260px',
          flex: 'auto',
          'justify-content': col.textAlign,
          position: col.position
        }"
      >
        <div
          *ngIf="
            !customColumnsLayout?.includes(col.key) &&
            col.key !== 'icons' &&
            col.type !== 'DatePicker' &&
            col.constraints__type === null
          "
          style="
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            display: flex;
            align-items: center;
            gap: 5px;
          "
          pTooltip="{{ data[col.key] | translate }}"
          tooltipPosition="bottom"
        >
          @if(col.key === 'test_result' && data['test_result_color']) {
          <div
            class="test_result_dot"
            [ngStyle]="{ background: data['test_result_color'] }"
          ></div>
          }
          {{ data[col.key] ? (data[col.key] | translate) : "-" }}
        </div>

        <span *ngIf="col.key === 'icons'">
          <ng-container *ngFor="let icon of data[col.key]">
            <ng-container *ngFor="let item of alcotestIcons">
              <img
                *ngIf="icon === item.value"
                class="alcotest_icon"
                [src]="item.path"
                [alt]="item.alt"
                pTooltip="{{ item.tooltip }}"
                tooltipPosition="bottom"
              />
            </ng-container>
          </ng-container>
        </span>

        <span *ngIf="col.type === 'DatePicker'">
          @if( col.key.includes('Calibration') || col.key.includes('Date')) {
          {{ data[col.key] ? (data[col.key] | translate) : "-" }}
          }@else {
          {{
            data[col.key] ? (data[col.key] | date : "dd/MM/YYYY - HH:mm") : "-"
          }}
          }
        </span>

        <span *ngIf="col.constraints__type === 'TIME'">
          @if( !data[col.key]?.includes('Z')) {
          {{ data[col.key] ? (data[col.key] | translate) : "-" }}
          }@else {
          {{ data[col.key] ? (data[col.key] | date : "HH:mm") : "-" }}
          }
        </span>

        <span
          *ngFor="let customColumn of customColumnsLayout; index as i"
          class="coordinates-row"
        >
          <span *ngIf="col.key === customColumn">
            <ng-template
              ngFor
              let-template
              [ngForOf]="customColumnsRef"
              let-j="index"
            >
              <div *ngIf="i === j">
                <ng-container
                  *ngTemplateOutlet="
                    template;
                    context: { $implicit: data[col.key] }
                  "
                ></ng-container>
              </div>
            </ng-template>
          </span>
        </span>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td>{{ "no_entries_found" | translate }}</td>
    </tr>
  </ng-template>
  <ng-template pTemplate="paginatorright" let-state>
    <span> {{ "of" | translate }} {{ " " + state.pageCount }}</span>
  </ng-template>
</p-table>
