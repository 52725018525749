import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITestDataDrugCheckDetails, ITestDataDrugCheckPage } from './../../models/test-data-drugcheck.model';

export interface ITestDataDrugCheckState {
  testDataDrugCheckPage: IStoreApiItem<ITestDataDrugCheckPage | any>;
  testDataDrugCheckDetails: IStoreApiItem<ITestDataDrugCheckDetails>;
  testDataDrugCheckColumns: IStoreApiItem<any>;
  testDataDrugCheckLabResults: IStoreApiItem<any>;
  testDataDrugCheckDeletedSection: IStoreApiItem<any>;

}

export class TestDataDrugCheckState implements ITestDataDrugCheckState {
  testDataDrugCheckPage: IStoreApiItem<ITestDataDrugCheckPage | any> = new StoreApiItem();
  testDataDrugCheckDetails: IStoreApiItem<ITestDataDrugCheckDetails> = new StoreApiItem();
  testDataDrugCheckColumns: IStoreApiItem<any> = new StoreApiItem();
  testDataDrugCheckLabResults: IStoreApiItem<any> = new StoreApiItem();
  testDataDrugCheckDeletedSection: IStoreApiItem<any> = new StoreApiItem();

}

