import { IFiltersOptions } from './../../models/statistics.model'; 
import { IStoreApiList, StoreApiList } from 'src/app/common';

export interface IFiltersOptionsState {
  filterOption: IStoreApiList<IFiltersOptions>;
}

export class FiltersOptionsState implements IFiltersOptionsState {
  filterOption: IStoreApiList<IFiltersOptions> = new StoreApiList();
}
