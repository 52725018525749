import { selectPermissions } from './../../state/role-management/selectors/role.selector';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription, takeUntil } from 'rxjs';
import { CommonConstants, IApplicationState, IStoreApiList } from 'src/app/common';
import { IPermissionResponse, IRolePermission } from '../../state/role-management/models/role.model';

@Injectable()
export class PermissionsService {
  moduleFeatureToggle: boolean;
  permissionList: IPermissionResponse;

  constructor(
    private store: Store<IApplicationState>,
    public router: Router,
  ) { }

  public hasModuleAccess(module: string): boolean {
    return this.checkPermission(this.permissionList, module);
  }

  public checkPermission(permissions: IPermissionResponse, module: string) {
    let hasPermission: boolean = false;
    permissions?.permissions?.some((perm: any) => {
      if (perm.permission === module) {
        hasPermission = perm.enabled;
      }
    });

    return hasPermission;
  }

  /* istanbul ignore next */
  public readPermissionListFromStore(destroy: any, permission: string): Subscription {
    return this.store
      .pipe(select(selectPermissions))
      .pipe(takeUntil(destroy))
      .subscribe((state: IStoreApiList<IPermissionResponse>) => {
        this.permissionList = state?.data;
        this.isPermissionEnabled(permission);
        this.canActivateModule(permission);
      });
  }

  /* istanbul ignore next */
  isPermissionEnabled(permission: string): void {
    this.permissionList?.permissions?.forEach((perm: IRolePermission) => {
      if (Object.values(perm).includes(permission)) {
        this.moduleFeatureToggle = perm.enabled;
      }
    });
  }

  /* istanbul ignore next */
  canActivateModule(permission: string): boolean {
    if (!localStorage.getItem(CommonConstants.AUTH_TOKEN)) {
      this.router.navigate(['/']);

      return false;
    }

    this.isPermissionEnabled(permission);

    if (this.moduleFeatureToggle === false) {
      return this.navigateToOverviewPage();
    } else {
      return true;
    }
  }

  public navigateToOverviewPage(): boolean {
    this.setLoginDisplay();

    return false;
  }

  setLoginDisplay() {
    this.router.navigate(['']);
  }
}
