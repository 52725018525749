import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { INotificationState } from './../models/notification.model';
import { RequestShowNotification } from './notification-action-types-creators';
import { NotificationTypes } from '../models/notification.model';

@Injectable({ providedIn: 'root' })
export class NotificationActions {
  constructor(private store: Store<INotificationState>) { }

  public requestShowNotification(type: NotificationTypes, message: string, value?: string, category?: string): void {
    this.store.dispatch(new RequestShowNotification({ type, message, value, category }));
  }
}
