import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ITestDataDrugCheckState } from './../models/test-data-drugcheck.model';
import { RequestSectionDelete, RequestTestDataDrugCheckDetails, RequestUpdateLabResult } from './test-data-drugcheck-action-types-creators/test-data-drugcheck-details-action-types-creators';
import { RequestTestDataDrugCheckPage, RequestUpdateDrugCheckColumns } from './test-data-drugcheck-action-types-creators/test-data-drugcheck-page-action-types-creators';

@Injectable({ providedIn: 'root' })
export class TestDataDrugCheckActions {
  constructor(private store: Store<ITestDataDrugCheckState>) { }

  public requestTestDataDrugCheckPage(params?: any): void {
    this.store.dispatch(new RequestTestDataDrugCheckPage(params));
  }

  public requestTestDataDrugCheckDetails(params?: any): void {
    this.store.dispatch(new RequestTestDataDrugCheckDetails(params));
  }

  public requestSectionDelete(params: any): void {
    this.store.dispatch(new RequestSectionDelete(params));
  }

  public requestUpdateLabResult(result: any): void {
    this.store.dispatch(new RequestUpdateLabResult(result));
  }

  public requestUpdateDrugCheckColumns(params: any): void {
    this.store.dispatch(new RequestUpdateDrugCheckColumns(params));
  }
}
