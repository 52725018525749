import { IStoreApiItem, StoreApiItem } from 'src/app/common/models/store-api-item.model';
import { ITestDataAlcotestDetails,ITestDataAlcotestPage } from './../../models/test-data-alcotest.model';

export interface ITestDataAlcotestState {
  testDataAlcotestPage: IStoreApiItem<ITestDataAlcotestPage>;
  testDataAlcotestDetails: IStoreApiItem<ITestDataAlcotestDetails>;
  testDataAlcotestColumns: IStoreApiItem<any>;
}

export class TestDataAlcotestState implements ITestDataAlcotestState {
  testDataAlcotestPage: IStoreApiItem<ITestDataAlcotestPage> = new StoreApiItem();
  testDataAlcotestDetails: IStoreApiItem<ITestDataAlcotestDetails> = new StoreApiItem();
  testDataAlcotestColumns: IStoreApiItem<any> = new StoreApiItem();
}

